.picker {
    display: flex;
    border-bottom: 1px solid white;
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
}

.picker input {
    display: none;
}

.myControl {
    background: none;
    background-color: transparent !important;
    border: none !important;
}

.myControl[aria-haspopup="false"] .myarrow {
    bottom: 2px;
}

.myarrow {
border-style: solid !important;
border-width: 0 0 6px 6px !important;
border-color: transparent transparent #ffffff transparent !important;
border-bottom-right-radius: 0 !important;
height: 6px !important;
width: 6px !important;

}

.menus {
    font-family: zona_pro;
    font-size: 14px;
}

.menus::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.menus::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.menus::-webkit-scrollbar-thumb {
    background: #7da3ca;
}

/* Handle on hover */
.menus::-webkit-scrollbar-thumb:hover {
    background: #4679ad;
}




.spTheme {
    --styled-select-placeholder__color: #fff;
    --styled-select-arrow-zone__width: 25px;

    --styled-select-arrow__color: #fff;
    --styled-select-arrow__size: 6;

    --styled-select-clear-zone__width: 17px;

    --styled-select-clear__color: #999;
    --styled-select-clear__font-size: 14px;

    --styled-select-control__border-color: #dcdce3;
    --styled-select-control__border-color--focused: #40a3f5;
    --styled-select-control__cursor--disabled: not-allowed;
    --styled-select-control__min-height: 23px;

    --styled-select-input__height: 22px;
    --styled-select-input__line-height: 22px;
    --styled-select-input__padding: 0;

    --styled-select-menu-outer__background-color: #fff;
    --styled-select-menu-outer__border-color: #f0f0f5;
    --styled-select-menu-outer__border-radius: 2px;
    --styled-select-menu-outer__border-style: solid;
    --styled-select-menu-outer__border-width: 1px;
    --styled-select-menu-outer__box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    --styled-select-menu-outer__margin: 5px 0 0 0;
    --styled-select-menu-outer__max-height: 200px;
    --styled-select-menu-outer__padding: 0;

    --styled-select-menu__border-radius: 2px;
    --styled-select-menu__max-height: 198px;

    --styled-select-multi-value-wrapper__padding: 3px 0 3px 5px;

    --styled-select-multi-value__background-color: #eee;
    --styled-select-multi-value__border: 1px solid #aaa;
    --styled-select-multi-value__border--hover: 1px solid #777;
    --styled-select-multi-value__border-radius: 3px;
    --styled-select-multi-value__box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
    --styled-select-multi-value__font-size: 0.9em;
    --styled-select-multi-value__line-height: 1.4;
    --styled-select-multi-value__margin: 2px 5px 2px 0;

    --styled-select-no-results__color: #999;
    --styled-select-no-results__font-family: Tahoma, Helvetica, Arial,
        sans-serif;
    --styled-select-no-results__font-size: 14px;
    --styled-select-no-results__padding: 8px 10px;

    --styled-select-option__background-color: #fff;
    --styled-select-option__background-color--focused: #f0f0f5;
    --styled-select-option__background-color--selected: #7da3ca;
    --styled-select-option__color: #777;
    --styled-select-option__color--focused: #333;
    --styled-select-option__color--selected: #fff;
    --styled-select-option__font-family: Tahoma, Helvetica, Arial, sans-serif;
    --styled-select-option__padding: 8px 10px;

    --styled-select-placeholder__color: #fff;
    --styled-select-placeholder__font-family: 'Montserrat';
    --styled-select-placeholder__font-size: 0.6em;
    --styled-select-placeholder__line-height:22px;
    --styled-select-placeholder__padding: 0;

    --styled-select-value-icon__background-color: transparent;
    --styled-select-value-icon__background-color--hover: rgba(0, 0, 0, 0.1);
    --styled-select-value-icon__font-family: arial;
    --styled-select-value-icon__padding: 1px 5px;

    --styled-select-value-label__font-family: Tahoma, Helvetica, Arial,
        sans-serif;
    --styled-select-value-label__padding: 1px 6px;

    --styled-select-value-wrapper__align-content: space-around;
    --styled-select-value-wrapper__align-items: center;
    --styled-select-value-wrapper__box-sizing: border-box;
    --styled-select-value-wrapper__display: flex;
    --styled-select-value-wrapper__flex: 2 100%;
    --styled-select-value-wrapper__padding: 0 0 0 5px;

    --styled-select-value__color: zona_pro;
    --styled-select-value__font-size: 0.6em;
    --styled-select-value__line-height: 22px;
    --styled-select-value__max-width: 100%;
    --styled-select-value__overflow: hidden;
    --styled-select-value__padding: 0;
    --styled-select-value__text-overflow: ellipsis;
    --styled-select-value__white-space: nowrap;

    --styled-select__background-color: #fff;
    --styled-select__border-radius: 2px;
    --styled-select__border-style: solid;
    --styled-select__border-width: 0;
    --styled-select__box-sizing: border-box;
    --styled-select__color: #fff;
    --styled-select__cursor--disabled: not-allowed;
    --styled-select__opacity--disabled: 0.5;
    --styled-select__pointer-events--disabled: none;
    --styled-select__position: relative;
}



@media (min-width: 1621px) {
    .spTheme {
        --styled-select-placeholder__font-size: 19px;
        --styled-select-value__font-size: 19px;
        --styled-select-arrow__size: 8;
    }

    .myarrow {
        border-style: solid !important;
        border-width: 0 0 8px 8px !important;
        border-color: transparent transparent #ffffff transparent !important;
        border-bottom-right-radius: 0 !important;
        height: 8px !important;;
        width: 8px !important;;
        
        }
}


@media (max-width: 900px) {
    .spTheme {
        --styled-select-value__font-size: 4vw;
        --styled-select-placeholder__font-size: 4vw;
        --styled-select-placeholder__line-height:4vw;
        --styled-select-input__height: 4vw;
        --styled-select-input__line-height: 4vw;
        --styled-select-control__min-height: 4vw;
        --styled-select-value__line-height: 4vw;
    }
    .menus {
        font-size: 4vw;
    }
}