:global #edit {
    padding: 1% 5%;
}

:global .notification-success,
:global .notification-success .timer {
    background-color: #ff326d;
}

:global .notification-success {
    border-left: 8px solid #af0f3d;
}

:global .notification-success .notification-close {
    background-color: #af0f3d;
}

/*top section*/
.top_section,
.bottom_section {
    width: 90%;
    margin: 0 auto;
}

.top_section h3 {
    font-weight: normal;
}

.top_section .text {
    font-size: 15px;
    font-weight: 300;
}

.form_box {
    display: flex;
}

.form_box form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.form_section {
    width: 49%;
}

.top_section h5 {
    font-weight: 300;
    font-size: 16px;
}

.bottom_section {
    padding: 5% 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.bottom_section button:last-child {
    margin-left: auto;
}

.settings {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.settings .text {
    font-size: 10px;
    font-weight: normal;
    font-family: 'Montserrat', Arial, sans-serif;
    width: 25%;
}

@media (max-width: 768px) {
    :global #edit {
        padding: 0;
    }

    .top_section h3 {
        font-size: 18px;
    }

    .form_section {
        width: 100%;
    }
    .settings {
        flex-direction: column;
    }
    .settings .text {
        width: 100%;
        font-size: 12px;
    }

    .bottom_section button:last-child {
        margin-left: 4px;
    }

    .bottom_section {
        justify-content: space-between;
    }

    .bottom_section button {
        width: 45%;
        font-size: 3.3vw;
    }
}
