.main {
  font-family: zona_pro;
  color: white;
  text-align: center;
  font-size: 20px;
  width: 100%;
  padding-bottom: 2%;
  margin-top: auto;
  margin-bottom: auto;

  h1 {
    font-size: 3em;
    font-family: zona_problack;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.25em;
    font-weight: normal;
  }

  p {
    font-size: 1em;
  }

  .h3-m-b {
    margin-bottom: 5%;
  }

  .container_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .bottom-text-box h3 {
    font-family: zona_pro;
    margin-bottom: 2%;
  }

  .img-box {
    width: 100%;
  }

  .img-box img {
    max-width: 60%;
    margin: 3em;
  }

  .btn-cstm {
    font-family: zona_pro;
    letter-spacing: 3px;
    margin: 20px 0;
    padding: 15px 40px;
    font-size: 1.2em;
    text-align: center;
    color: white;
    background-color: black;
    border: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 1536px) {
  .main {
    font-size: 18px;
  }
}

@media screen and (max-width: 1440px) {
  .main {
    font-size: 17px;
    .img-box img {
      margin: 0;
    }
    .bottom-text-box h3 {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  .main {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
    font-size: 20px;
    .bottom-text-box {
      margin-top: 5%;
    }

    .img-box img {
      max-width: 75%;
    }

    .bottom-text-box h3 {
      margin-bottom: 6%;
    }
  }
}

@media screen and (max-width: 834px) {
  .main {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    font-size: 18px;
    .container_content {
      margin: 0 1%;
    }
  }
}

@media screen and (max-width: 680px) {
  .main {
    font-size: 16px;
    h1 {
      font-size: 2.3em;
    }

    h3 {
      font-size: 1.1em;
    }

    p {
      font-size: 0.8em;
    }
    .img-box img {
      max-width: 50%;
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    font-size: 14px;
    h1 {
      font-size: 2.1em;
    }

    h3 {
      font-size: 1em;
    }
  }
}

@media screen and (max-width: 375px) {
  .main {
    font-size: 13px;
  }
}
