
.btn_disabled {
    visibility: hidden;
}

.btn,
.btn_grey {
    font-family: 'Montserrat', Arial, sans-serif;
    display: inline-block;
    line-height: 24px;
    /* margin: 0px 10px; */
    padding: 6px 10px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    position: relative;
    border: 2px solid #7F28C4;
    border-radius: 4px;
    background-color: #ffffff;
    color: #7F28C4;
    cursor: pointer;

    margin: 4px;
}

.btn_grey {
    color: grey;
    border-color: grey;
    cursor: unset;
}

.btn:hover {
    border: 2px solid transparent;
    background-color: rgba(106, 23, 173, 0.05);
    color: #6A17AD;
}


.btn:active,
.btn:focus,
.btn_grey:active,
.btn_grey:focus {
    outline: unset;
}
