.middle {
    background-color: #ea6461;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.sliderwrapper {
    max-width: 100vw;
    padding: 2vw 0;
}

.middle_blue {
    background-image: linear-gradient(
            rgba(88, 168, 238, 0.7) 33.33%,
            transparent 33.33%,
            transparent 66.66%,
            rgba(184, 0, 141, 0.3) 66.66%
        ),
        linear-gradient(
            90deg,
            #d991b7 25%,
            #d37eac 25%,
            #d37eac 50%,
            #d881af 50%,
            #d881af 75%,
            #876492 75%
        );
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.section_liiv {
    width: 95%;
    margin: auto;
}

.liiv {
    background: #f7f7f7;
    color: #7b6368 !important;
    font-family: "Montserrat", Arial, sans-serif !important;
}

.xmas,
.universal {
    background: #fff;
    font-family: "Montserrat", Arial, sans-serif !important;
    color: #000;
}

.xmas h2,
.universal h2 {
    text-align: center;
    font-weight: 900;
    font-size: 2vw;
    margin: 3vw auto;
    width: 57%;
}

.universal h4 {
    font-size: 23px;
    font-weight: 400;
}

@media (max-width: 900px) {
    .xmas h2 {
        font-size: 23px;
        margin-bottom: 11vw;
    }
}

.xmasicons {
    display: flex;
    justify-content: space-between;
    background-image: url(../../assets/img/background/xmas-bg.jpg);
    background-repeat: repeat-x;
    background-position: center bottom;
    padding: 2vw 4vw 15vw 4vw;
}

.universal [class*="iconSection"] {
    margin: 0;
    width: 100%;
    height: auto;
    background-image: url(../../assets/img/universal/bg2.jpg);

    margin-bottom: 40px;

    background-repeat: repeat-x;
    background-position: center bottom;
    padding: 2vw 4vw 15vw 4vw;
}

.xmas [class*="message_box"] img,
.universal [class*="message_box"] img {
    filter: brightness(0);
}

.universal [class*="iconBox_iconsbottom"] {
    color: #000;
}

.universal [class*="iconBox_text"] {
    margin-top: 30px;
}

.universal [class*="iconBox_img"] {
    height: 80px;
    margin: 0;
}

.universal [class*="iconBox_iconsbottom"]:last-child {
    display: flex;
}

.xmas .goldbar [class*="message_box"] img {
    filter: brightness(10);
}

.xmasicons > div {
    text-align: center;
    width: 9vw;
}

.xmasicons > div img {
    height: 5vw;
}

.xmas .sliderwrapper {
    padding-top: 0;
}

.xmasbaner:before,
.xmasbaner:after,
.xmas .sliderwrapper:before,
.xmas .sliderwrapper:after {
    content: "";
    display: block;
    height: 10px;
    width: 100%;
    background: rgb(194, 154, 72);
    background: -moz-linear-gradient(
        90deg,
        rgba(194, 154, 72, 1) 0%,
        rgba(238, 211, 154, 1) 38%,
        rgba(201, 162, 85, 1) 66%,
        rgba(200, 161, 83, 1) 86%,
        rgba(253, 230, 182, 1) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(194, 154, 72, 1) 0%,
        rgba(238, 211, 154, 1) 38%,
        rgba(201, 162, 85, 1) 66%,
        rgba(200, 161, 83, 1) 86%,
        rgba(253, 230, 182, 1) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(194, 154, 72, 1) 0%,
        rgba(238, 211, 154, 1) 38%,
        rgba(201, 162, 85, 1) 66%,
        rgba(200, 161, 83, 1) 86%,
        rgba(253, 230, 182, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c29a48",endColorstr="#fde6b6",GradientType=1);
}

.xmas .sliderwrapper:after {
    margin-top: -5px;
}

.xmasbaner img,
.universal img,
.xmas .sliderwrapper img {
    display: block;
    width: 100%;
}

.xmashow {
    display: flex;
    justify-content: space-between;
}

.u_show {
    display: flex;
    justify-content: space-between;
}

.u_show > div {
    width: 50%;
}

.xmashow > div {
    width: 50%;
}

.xmashow > div:last-child {
    padding-top: 100px;
}

.xmashow > div:last-child > div > div {
    position: relative;
    margin-bottom: 4vw;
    margin-left: 1vw;
}

.xmashow > div:last-child > div > div:last-child {
    margin-bottom: 0;
}
.xmashow > div:last-child > div > div > div:first-child {
    font-size: 7vw;
    font-weight: 900;
    color: #d8cbb1;
    position: absolute;
    line-height: 1;
    z-index: 0;
}

.xmas .goldbar {
    background-image: url(../../assets/img/goldenbar.jpg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 173px;
    flex-direction: column;
    margin-bottom: 5vw;
}

.xmas .goldbar h4 {
    color: #fff;
    font-weight: 700;
    font-size: 26px;
}

.xmas .goldbar textarea {
    font-size: 18px;
    font-weight: 700;
}

.xmas .goldbar:before,
.xmas .goldbar:after {
    content: "";
    display: block;
    height: 10px;
    width: 100%;
    background: rgb(194, 154, 72);
    background: -moz-linear-gradient(
        90deg,
        rgba(194, 154, 72, 1) 0%,
        rgba(238, 211, 154, 1) 38%,
        rgba(201, 162, 85, 1) 66%,
        rgba(200, 161, 83, 1) 86%,
        rgba(253, 230, 182, 1) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(194, 154, 72, 1) 0%,
        rgba(238, 211, 154, 1) 38%,
        rgba(201, 162, 85, 1) 66%,
        rgba(200, 161, 83, 1) 86%,
        rgba(253, 230, 182, 1) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(194, 154, 72, 1) 0%,
        rgba(238, 211, 154, 1) 38%,
        rgba(201, 162, 85, 1) 66%,
        rgba(200, 161, 83, 1) 86%,
        rgba(253, 230, 182, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c29a48",endColorstr="#fde6b6",GradientType=1);
}

.xmashow > div:last-child > div > div > div:last-child {
    position: relative;
    z-index: 1;
    padding-top: 4.3vw;
    padding-left: 3vw;
}

.u_show > div:first-child {
    width: 60%;
    padding-left: 50px;
}

.u_show > div:last-child {
    padding-left: 20px;
}

.u_show > div:first-child > div > div {
    position: relative;
    border: 10px solid #7f28c4;
    margin: 30px 0 30px 50px;
    box-sizing: border-box;
    padding: 60px;
    border-radius: 50px 0 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 190px;
}

.u_show > div:first-child > div > div > div:first-child {
    position: absolute;
    left: -58px;
    bottom: -16px;
    font-size: 150px;
    font-weight: 900;
    line-height: 120px;
    text-align: center;
    color: #7f28c4;
    background: #fff;
}

.xmashow > div:last-child > div > div > div:last-child > div:first-child {
    font-weight: 900;
    font-size: 26px;
}

.xmas h3,
.universal h3 {
    font-weight: 900;
    font-size: 1.8vw;
    margin: 1vw 0;
}

.xmas .video_section > div:before {
    content: "";
    background-image: url(../../assets/img/ribbon-top.png);
    width: 100%;
    height: 8vw;
    position: absolute;
    top: calc(-8vw - 10px);
    left: 0;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
}

.xmas .video_section > div:after {
    content: "";
    background-image: url(../../assets/img/ribbon-bottom.png);
    width: 100%;
    height: 8vw;
    position: absolute;
    bottom: calc(-8vw - 10px);
    left: 0;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top;
}

.liiv div[class*="iconBox_text"] {
    color: #7b6368 !important;
    font-weight: 400 !important;
    font-family: "Montserrat", Arial, sans-serif !important;
}

.liiv [class*="input_box"] input,
.liiv [class*="textarea"] textarea,
.liiv h4,
.liiv h2 {
    font-family: "Abril Fatface" !important;
    color: #7b6368 !important;
    text-align: center;
    font-weight: normal;
}

@media (max-width: 900px) {
    .xmashow {
        flex-direction: column;
    }

    .xmashow > div {
        width: 100%;
    }
    .xmashow > div:last-child > div > div > div:first-child {
        font-size: 145px;
    }

    .xmashow > div:last-child > div > div > div:last-child {
        padding-top: 90px;
        padding-left: 40px;
    }

    .xmashow > div:last-child {
        padding-top: 0;
        padding: 0 30px 40px 30px;
    }

    .xmas h3 {
        font-size: 23px;
        margin: 30px 0 10px 0;
        width: 70%;
    }

    .xmas .goldbar h4 {
        font-size: 20px;
        text-align: center;
        padding: 0 20px;
    }
}

@media (max-width: 900px) {
    .xmasicons {
        flex-direction: column;
        background-size: auto 40vw;
        padding-bottom: 20vw;
    }

    .xmasicons > div {
        width: 100%;
        margin-bottom: 10vw;
    }

    .xmasicons > div img {
        height: auto;
        width: 25vw;
        max-width: 93px;
    }

    .xmasicons > div p {
        font-size: 1em;
        width: 50%;
        margin: 10px auto;
    }
}

.liiv h4 {
    font-size: 28px;
}

.liiv .video_section div {
    -webkit-box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.16);
}
@media (max-width: 900px) {
    .liiv h4 {
        font-size: 20px;
    }
}

:global .slick-slide img {
    width: 100vw;
    height: auto;
    border: none;
}

:global .slick-slide,
:global .slick-slider,
:global .slick-slide * {
    outline: none !important;
    border: none;
}

:global .slick-slide {
    position: relative;
}

:global .slick-slide .slidetext {
    position: absolute;
    display: flex;
    width: 40%;
    left: 10%;
    top: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

@media (max-width: 900px) {
    :global .slick-slide .slidetext {
        width: 100%;
        justify-content: flex-start;
        left: 0;
    }

    :global .slick-slide .slidetext p {
        width: 70%;
        font-size: 3vw;
    }

    :global .slick-slide .slidetext h3 {
        font-size: 4vw;
    }
}

:global .slick-slide .slidetext h3 {
    font-family: "Abril Fatface";
    font-weight: normal;
}

@media (min-width: 1024px) {
    :global .slick-slide .slidetext h3 {
        font-size: 2vw;
    }

    :global .slick-slide .slidetext p {
        font-size: 1vw;
    }
}

:global .slick-prev:before,
:global .slick-next:before {
    display: none;
}

:global .slick-prev,
:global .slick-next {
    width: 55px;
    height: 55px;
    background: #fff !important;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
}

.xmas :global .slick-next,
.xmas :global .slick-prev {
    background: none !important;
    box-shadow: none !important;
    bottom: -24px;
    top: auto;
    height: auto;
    display: block;
}

@media (max-width: 900px) {
    .xmas :global .slick-next,
    .xmas :global .slick-prev {
        bottom: -10px;
    }

    .xmas .goldbar {
        margin-bottom: 30px;
    }
}

.xmas :global .slick-next img,
.xmas :global .slick-prev img {
    max-width: 100%;
    transform: rotate(0);
    display: block;
}

:global .slick-next {
    right: 20px;
}

:global .slick-prev {
    left: 20px;
}

.xmas :global .slick-next {
    right: 0;
}

.xmas :global .slick-prev {
    left: 0;
}

:global .slick-prev img,
:global .slick-next img {
    max-width: 30%;
}

@media (max-width: 1024px) {
    :global .slick-prev,
    :global .slick-next {
        width: 30px;
        height: 30px;
    }

    :global .slick-next {
        right: 10px;
    }

    :global .slick-prev {
        left: 10px;
    }
}

:global .slick-prev img {
    transform: rotate(180deg);
}

.liiv [class*="dark_dark"],
.xmas [class*="dark_dark"],
.universal [class*="dark_dark"] {
    background: none;
    background-color: transparent !important;
}

.middle_green {
    background-image: linear-gradient(
            rgba(73, 211, 185, 0.7) 33.33%,
            transparent 33.33%,
            transparent 66.66%,
            rgba(184, 0, 141, 0.3) 66.66%
        ),
        linear-gradient(
            90deg,
            #d991b7 25%,
            #d37eac 25%,
            #d37eac 50%,
            #d881af 50%,
            #d881af 75%,
            #876492 75%
        );
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.middle_purple {
    background-image: linear-gradient(
            rgba(88, 68, 238, 0.7) 33.33%,
            transparent 33.33%,
            transparent 66.66%,
            rgba(184, 0, 141, 0.3) 66.66%
        ),
        linear-gradient(
            90deg,
            #d991b7 25%,
            #d37eac 25%,
            #d37eac 50%,
            #d881af 50%,
            #d881af 75%,
            #876492 75%
        );
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.textarea,
.input_box {
    border: 2px solid #dbdbdb;
    border-radius: 10px;
    padding: 1% 0;
    margin: 1% auto;
    width: 50%;
    position: relative;
}

.xmas [class*="textarea"],
.universal [class*="textarea"] {
    border-color: #000;
}

.xmas [class*="textarea"] textarea,
.universal [class*="textarea"] textarea {
    color: #000;
}

.xmas .goldbar [class*="textarea"] {
    border-color: #fff;
}

.xmas .goldbar [class*="textarea"] textarea {
    color: #fff;
}

.text_box {
    margin: 1% auto;
    width: 90%;
    text-align: center;
}

.textarea textarea {
    font-weight: normal;
    font-size: 26px;
    background-color: transparent;
    width: 95%;
    border: unset;
    color: white;
    font-family: zona_pro;
    margin: auto;
    display: block;
    text-align: center;
    resize: none;
}

textarea:focus {
    outline: unset;
}

.img_section,
.video_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
    position: relative;
}

.img_section img,
.video_section video {
    max-width: 100%;
    width: auto;
    max-height: 100%;
}

.input_box input:focus {
    outline: unset;
}

.video_section {
    height: auto;
    margin-top: 20px;
}

@media (min-width: 900px) {
    .video_section {
        margin-bottom: -120px;
    }
}

@media (min-width: 1100px) {
    .video_section {
        margin-bottom: -200px;
    }

    .xmas .video_section,
    .universal .video_section {
        margin-bottom: 0;
    }
}

.video_section div {
    padding: 2%;
    background-color: aliceblue;
    position: relative;
}

.universal .video_section div {
    background: none;
    z-index: 5;
}

.universal .video_section > div:last-child {
    position: absolute;
    z-index: 1;
    width: 900px;
}

.universal .video_section {
    margin-bottom: 8vw;
}

.xmas .video_section div {
    padding: 0;
    margin: 8vw 0;
    background-color: #8e0f41;
    border: 10px solid #8e0f41;
    position: relative;
    border-radius: 5px;
}

.aspect_ratio_box {
    height: 0;
    padding-top: calc(591.44 / 1051.34 * 70%);
    background: transparent;
    position: relative;
    width: 50%;
}

.universal .aspect_ratio_box_inside {
    box-sizing: border-box;
    border: 5px solid #000;
    border-radius: 100px 0 100px 0;
    height: 72%;
}

.universal .aspect_ratio_box {
    width: 60%;
}

.universal .aspect_ratio_box_inside img {
    max-height: 100%;
    width: auto;
}

.aspect_ratio_box_inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    height: 100%;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.u_video_header {
    color: #fff;
    background: #7f28c4;
    padding: 30px 0 200px 0;
    margin-bottom: -200px;
}

@media (max-width: 900px) {
    .xmas .video_section > div:before {
        top: -110px;
    }

    .xmas .video_section > div:after {
        bottom: -110px;
    }

    .xmas .video_section > div:after,
    .xmas .video_section > div:before {
        height: 100px;
    }

    .xmas .video_section > div {
        margin: 100px 0;
        height: 70vw;
    }
}

@media screen and (max-width: 1200px) {

    .u_show {
        margin-top: 40px;
    }
    .top {
        max-height: 1200px;
    }



    .universal h3 {
        font-size: 23px;
        text-align: center;
    }

    .universal h4 {
        font-size: 23px;
        text-align: center;
        font-weight: 400;
    }
}

@media screen and (max-width: 1080px) {
    .top {
        max-height: 1080px;
    }

    .universal h2 {
        font-size: 23px;
        margin-bottom: 11vw;
    }

    
}

@media screen and (max-width: 900px) {

    .universal [class*="iconSection"] {
        padding-bottom: 40vw;
    }
    .top {
        max-height: 900px;
    }

    .u_show {
        flex-direction: column;
        align-items: center;
    }

    .u_show > div {
        width: 90% !important;
        padding: 0 !important;
      
    }

    .universal  .aspect_ratio_box {
        width: 100%;
    }
}

@media screen and (max-height: 768px) {
    .top {
        max-height: 768px;
    }
}

@media screen and (max-width: 1620px) {
    .aspect_ratio_box {
        padding-top: calc(591.44 / 1051.34 * 80%);
        width: 60%;
    }
}

@media screen and (max-width: 1280px) {
    .textarea,
    .input_box {
        width: 60%;
    }

    .video_section div {
        width: 80%;
        height: 250px;
    }

    .xmas .video_section div,
    .universal .video_section div {
        height: auto;
    }

    .video_section iframe {
        max-width: 100%;
        max-height: 100%;
    }
}

@media screen and (max-width: 900px) {
    .video_section div {
        width: 70%;
        height: auto;
        margin-bottom: -10px;
    }

    .liiv .video_section div,
    .liiv .video_section iframe {
        width: 100%;
    }

    .middle_blue {
        background: #4679ad;
        padding-top: 5vw;
    }

    .middle_green {
        background: #46aba5;
        padding-top: 5vw;
    }

    .middle_purple {
        background: #ad8cc0;
        padding-top: 5vw;
    }
}

@media screen and (max-width: 768px) {
    .video_section iframe {
        width: 100%;
    }
    .aspect_ratio_box {
        padding-top: calc(591.44 / 851.34 * 100%);
        width: 100%;
    }

    .text_box {
        width: 95%;
    }

    .text_box h2 {
        font-size: 1.5em;
    }

    .img_section {
        max-width: 75%;
        margin: 0 auto;
    }

    .video_section div {
        padding: 0;
        background-color: unset;
        width: 100%;
    }
}

@media screen and (max-width: 375px) {
    .middle_blue {
        min-height: calc(100vh - 18%);
    }

    .text_box {
        width: 95%;
    }

    .text_box h2 {
        font-size: 1.3em;
    }

    .img_section {
        max-width: 85%;
    }
}

@media (max-width: 1100px) {
    .middle:before,
    .middle:after {
        content: "";
        flex-grow: 1;
        background-color: rgba(0, 0, 0, 0.5);
    }
}
