.correct {
    position: absolute;
    top: 0;
    right: 10px;
}

.pickerlabel {
    font-size: 0.4em;
    margin-bottom: 5px;
}

@media not all and (min-resolution: 0.001dpcm) {
    @media {
        .correct {
            top: 13px;
        }
    }
}

.correct:after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 6px;
    height: 13px;
    border: solid #2fa85a;
    border-width: 0px 2px 2px 0;
    transform: rotate(40deg);
}

.textInput {
    position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::placeholder {
    color: white;
    font-size: 0.6em;
}

@media (min-width: 1621px) {
    input::placeholder {
        font-size: 18px;
    }

    .pickerlabel {
        font-size: 14px;
        margin-bottom: 5px;
    }
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.errorBox {
    position: absolute;
    right: -10px;
    top: -5px;
    margin: 0 !important;
    width: auto;
    padding: 8px;
    border-radius: 25px;
}

.errorBox .errorBoxContent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
}

.errorBox img {
    width: 15px;
    height: 15px;
}

.errorBox:hover .error {
    display: block;
    min-width: 150px;
}

.error {
    font-family: zona_prosemibold;
    font-size: 11px;
    color: #af0c0c;
    background-color: #e2adc9;
    padding: 5px;
    margin: 0 !important;
    display: none;
    position: absolute;
    bottom: 110%;
    right: 50%;
    border-radius: 15px;
    width: auto;
}

.error.left {
    right: unset;
    left: 25%;
}

@media screen and (max-width: 1370px) {
    .correct:after {
        top: -5px;
    }
}

@media screen and (max-width: 1110px) {
    .correct:after {
        top: 10px;
    }
}

@media (max-width: 900px) {
    input::placeholder {
        font-size: 4vw;
    }

    .errorBox {
        top: -2vw;
    }

    .pickerlabel {
        font-size: 2vw;
    }
}

@media screen and (max-width: 800px) {
    .correct:after {
        top: 0;
    }

    .error.left {
        left: unset;
        right: 50%;
    }
}
