/*Input start*/
/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 11px;
    font-family: "zona_pro_light";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 95%;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
/*.container:hover input ~ .checkmark {*/
/*    */
/*}*/

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 6px;
    height: 13px;
    border: solid #2fa85a;
    border-width: 0px 2px 2px 0;
    transform: rotate(40deg);
}

:global .agreement {
    margin-bottom: 2px;
}

.error {
    font-family: zona_prosemibold;
    color: #000000;
    font-size: 11px;
    width: 100%;
    text-align: left;
    padding-top: 5px;
    position: absolute;
    margin: 0 !important;
}
/*Input stop*/

@media screen and (max-width: 375px) {
    .container {
        font-size: 9px;
    }

    .error {
        padding-top: 0;
    }

    :global .agreement {
        margin-bottom: 2px !important;
    }
}
