.dark {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 1100px) {
    .dark {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@media (max-width: 900px) {
    .dark {
        background: none;
    }
}
