@font-face {
    font-family: "zona_problack";
    src: url("assets/fonts/ZonaPro-Black.eot");
    src: url("assets/fonts/ZonaPro-Black.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-Black.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-Black.woff") format("woff"),
        url("assets/fonts/ZonaPro-Black.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-Black.svg#zona_problack") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "zona_pro";
    src: url("assets/fonts/ZonaPro-Regular.eot");
    src: url("assets/fonts/ZonaPro-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-Regular.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-Regular.woff") format("woff"),
        url("assets/fonts/ZonaPro-Regular.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-Regular.svg#zona_pro") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "zona_pro_italic";
    src: url("assets/fonts/ZonaPro-RegularItalic.eot");
    src: url("assets/fonts/ZonaPro-RegularItalic.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-RegularItalic.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-RegularItalic.woff") format("woff"),
        url("assets/fonts/ZonaPro-RegularItalic.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-RegularItalic.svg#zona_pro_italic")
            format("svg");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "zona_pro_light";
    src: url("assets/fonts/ZonaPro-Light.eot");
    src: url("assets/fonts/ZonaPro-Light.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-Light.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-Light.woff") format("woff"),
        url("assets/fonts/ZonaPro-Light.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-Light.svg#zona_pro_light") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "zona_pro_light_italic";
    src: url("assets/fonts/ZonaPro-LightItalic.eot");
    src: url("assets/fonts/ZonaPro-LightItalic.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-LightItalic.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-LightItalic.woff") format("woff"),
        url("assets/fonts/ZonaPro-LightItalic.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-LightItalic.svg#zona_pro_light_italic")
            format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "zona_pro_hairline";
    src: url("assets/fonts/ZonaPro-Hairline.eot");
    src: url("assets/fonts/ZonaPro-Hairline.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-Hairline.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-Hairline.woff") format("woff"),
        url("assets/fonts/ZonaPro-Hairline.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-Hairline.svg#zona_pro_hairline") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "zona_pro_hairline_italic";
    src: url("assets/fonts/ZonaPro-HairlineItalic.eot");
    src: url("assets/fonts/ZonaPro-HairlineItalic.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-HairlineItalic.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-HairlineItalic.woff") format("woff"),
        url("assets/fonts/ZonaPro-HairlineItalic.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-HairlineItalic.svg#zona_pro_hairline_italic")
            format("svg");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "zona_pro_bold";
    src: url("assets/fonts/ZonaPro-Bold.eot");
    src: url("assets/fonts/ZonaPro-Bold.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/ZonaPro-Bold.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-Bold.woff") format("woff"),
        url("assets/fonts/ZonaPro-Bold.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-Bold.svg#zona_pro_bold") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "zona_pro_extrabold";
    src: url("assets/fonts/ZonaPro-ExtraBold.eot");
    src: url("assets/fonts/ZonaPro-ExtraBold.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-ExtraBold.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-ExtraBold.woff") format("woff"),
        url("assets/fonts/ZonaPro-ExtraBold.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-ExtraBold.svg#zona_pro_extrabold_italic")
            format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "zona_pro_extrabold_italic";
    src: url("assets/fonts/ZonaPro-ExtraBoldItalic.eot");
    src: url("assets/fonts/ZonaPro-ExtraBoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-ExtraBoldItalic.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-ExtraBoldItalic.woff") format("woff"),
        url("assets/fonts/ZonaPro-ExtraBoldItalic.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-ExtraBoldItalic.svg#zona_pro_extrabold_italic")
            format("svg");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "zona_pro_bold_italic";
    src: url("assets/fonts/ZonaPro-BoldItalic.eot");
    src: url("assets/fonts/ZonaPro-BoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-BoldItalic.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-BoldItalic.woff") format("woff"),
        url("assets/fonts/ZonaPro-BoldItalic.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-BoldItalic.svg#zona_pro_bold_italic")
            format("svg");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "zona_prosemibold";
    src: url("assets/fonts/ZonaPro-SemiBold.eot");
    src: url("assets/fonts/ZonaPro-SemiBold.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-SemiBold.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-SemiBold.woff") format("woff"),
        url("assets/fonts/ZonaPro-SemiBold.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-SemiBold.svg#zona_prosemibold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "zona_prosemibold_italic";
    src: url("assets/fonts/ZonaPro-SemiBoldItalic.eot");
    src: url("assets/fonts/ZonaPro-SemiBoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-SemiBoldItalic.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-SemiBoldItalic.woff") format("woff"),
        url("assets/fonts/ZonaPro-SemiBoldItalic.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-SemiBoldItalic.svg#zona_prosemibold_italic")
            format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "zona_problack_italic";
    src: url("assets/fonts/ZonaPro-BlackItalic.eot");
    src: url("assets/fonts/ZonaPro-BlackItalic.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-BlackItalic.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-BlackItalic.woff") format("woff"),
        url("assets/fonts/ZonaPro-BlackItalic.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-BlackItalic.svg#zona_problack_italic")
            format("svg");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "zona_pro_thin";
    src: url("assets/fonts/ZonaPro-Thin.eot");
    src: url("assets/fonts/ZonaPro-Thin.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/ZonaPro-Thin.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-Thin.woff") format("woff"),
        url("assets/fonts/ZonaPro-Thin.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-Thin.svg#zona_pro_thin") format("svg");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "zona_pro_thin_italic";
    src: url("assets/fonts/ZonaPro-ThinItalic.eot");
    src: url("assets/fonts/ZonaPro-ThinItalic.eot?#iefix")
            format("embedded-opentype"),
        url("assets/fonts/ZonaPro-ThinItalic.woff2") format("woff2"),
        url("assets/fonts/ZonaPro-ThinItalic.woff") format("woff"),
        url("assets/fonts/ZonaPro-ThinItalic.ttf") format("truetype"),
        url("assets/fonts/ZonaPro-ThinItalic.svg#zona_pro_thin_italic")
            format("svg");
    font-weight: 100;
    font-style: normal;
}

.zona-pro-black {
    font-family: "zona_problack";
}

.zona-pro {
    font-family: "zona_pro";
}

.zona-pro-italic {
    font-family: "zona_pro_italic";
}

.zona-pro-light {
    font-family: "zona_pro_light";
}

.zona-pro-light-italic {
    font-family: "zona_pro_light_italic";
}

.zona-pro-hairline {
    font-family: "zona_pro_hairline";
}

.zona-pro-hairline-italic {
    font-family: "zona_pro_hairline_italic";
}

.zona-pro-bold {
    font-family: "zona_pro_bold";
}

.zona-pro-bold-italic {
    font-family: "zona_pro_bold_italic";
}

.zona-pro-semibold {
    font-family: "zona_prosemibold";
}

.zona-pro-semibold-italic {
    font-family: "zona_prosemibold_italic";
}

.zona-problack-italic {
    font-family: "zona_problack_italic";
}

.zona-pro-thin {
    font-family: "zona_pro_thin";
}

.zona-pro-thin-italic {
    font-family: "zona_pro_thin_italic";
}

.zona-pro-extrabold {
    font-family: "zona_pro_extrabold";
}

.zona-pro-extrabold-italic {
    font-family: "zona_pro_extrabold_italic";
}
