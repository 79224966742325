.content {
    margin: 5% auto;
}

.title {
    font-size: 1.5em;
    font-weight: normal;
    text-align: center;
    margin: 2.5em;
}

.text1,
.text2 {
    max-width: 50%;
    margin: 3em auto;
}

.text2 {
    max-width: 70%;
}

.tooltipMobile {
  display: none;
}

@media (max-width: 992px) {
    .text1,
    .text2 {
        max-width: 90%;
        margin: 3em auto;
    }

    .title p {
        font-size: 23px;
    }
}

@media (max-width: 768px) {
    .tooltipMobile {
        display: block;
        max-width: 90%;
        margin: 3em auto;
        background-color: #E1EBF4;
        padding: 10px;
        box-shadow: 3px 5px #E6E6E6;
        border-radius: 5px;
        text-align: left;
    }

}
