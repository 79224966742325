.landing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    height: 100vh;
}

@media (max-width: 900px) {
    .landing {
        flex-direction: column-reverse;
        height: auto;
        max-height: none;
    }
}
