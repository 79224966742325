.section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 75%;
    margin: 0 auto 3%;
    height: 200px;
}

@media screen and (max-width: 1620px) {
    .section {
        width: 90%;
    }
}

@media (max-width: 900px) {
    .section {
        height: auto;
    }

    .section.iconsbottom {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    .section {
        width: 100%;
    }
}
