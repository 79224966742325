.main {
    font-weight: 200;
}

.flexrow {
    font-weight: 200;
}

.mobilelabel {
    font-size: 16px;
    margin-bottom: 10px;
}

@media (min-width: 993px) {
    .mobilelabel {
        display: none;
    }
}
