.page_section {
    display: flex;
    flex-direction: column;
    justify-content: start;
    color: white;
    margin: 0 auto;
    overflow-wrap: break-word;
}
.page_section h5 {
    font-weight: 300;
    font-size: 16px;
    color: black;
}

.page_section .text {
    font-size: 15px;
    font-weight: 300;
}

@media screen and (max-width: 768px) {
    .page_section {
        width: 100%;
        height: 100%;
        max-height: 100%;
    }
}
