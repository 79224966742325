.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.box h3,
.box h5 {
    font-weight: 300;
    margin: 5px auto;
}

.box h5 a {
    text-decoration: underline;
    color: #0359e2;
}

.input {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.input > button {
    padding: 4px 8px;
}

.input_box {
    width: 65%;
    border-radius: 0 5px 5px 0;
    border: #7F28C4 1px solid;
    color: grey;
    line-height: 1em;
    font-size: 1em;
    padding: 0.5em;
    margin-left: -1px;
    text-align: center;
}
