.data_box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.data_box p {
    text-align: center;
}

.data_box .number {
    font-size: 2.2em;
    font-weight: bold;
    margin: 0;
}

@media (max-width: 768px) {
    .data_box {
        width: 50%;
    }
}
