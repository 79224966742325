$secondary: #E5004B;
$pink: #FFEFF4;
$main: #212121;

.landing {
    font-size: 15px;
    line-height: 1.3;
    font-weight: 400;
    text-align: left;
    color: #212121;
    .button {
        font-family: 'Montserrat', Arial, sans-serif;
        font-weight: 600;
        margin: 5px 0;
        padding: 16px 30px;
        font-size: 1em;
        text-align: center;
        color: white;
        background-color: #212121;
        border: none;
        cursor: pointer;
        display: inline-block;
        text-wrap: nowrap;
        transition: all 0.3s ease-in-out;
        position: relative;
        z-index: 100;
    }
    .button:hover {
        background-color: #E5004B;
    }
    .pointer {
        cursor: pointer;
    }

    .mobileScale {
        @media (max-width: 500px) {
           font-size: 3.7vw !important;
        }
    }
    .benefits {
        font-size: 18px;
        margin-top: 100px;
    }
    .text_left {
        text-align: left;
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        margin: 0;
    }
    .bg_whiter {
        background-color: rgba($color: #ffffff, $alpha: 0.7);
    }
    @media (max-width: 767px) {
    .logo {
        width: 63px;
    }
}
    @media (min-width: 1200px) {
        .h1, h1 {
            font-size: 2.5rem;
        }
    }
    
    @media (min-width: 1400px) {
        .h1, h1 {
            font-size: 3.5rem;
        }
    }
    .secondary {
        color: $secondary;
    }
    .pinkbox {
        background: $pink;
    }
    .text_semibold {
        font-weight: 600;
    }
    a {color:$main}
    .qr {
        width: 100%;
        max-width: 100px;
        display: block;
    }
    .box1 {
        background-image: url("../../../assets/img/new_template/avonon-lp.png");
        background-size: cover;
        min-height: 350px;
    }
    .box2 {
        background-image: url("../../../assets/img/new_template/katalog-online.png");
        background-size: cover;
        min-height: 350px;
    }
    .ytWrap {
        position: relative;
        .ytHeader {
            background-color: #ffffff;
            position: absolute;
            left: 0;
            bottom: 10%;
            .ytLink {
                cursor: pointer;
            }
            @media (max-width: 767px) {
                position: relative;
                margin:0 20px;
                top: -100px;
                margin-bottom: -80px;
            }
        }
    }
}