.containerCustom,
.container_left {
    width: 1640px;
    margin: 0 auto;
    text-align: center;
    max-width: 100%;
}

.margin {
    margin-top: 70px;
}

.container_left {
    text-align: left;
}

.header {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: white;
}

@media screen and (max-width: 1640px) {
    .container_left {
        width: 1440px;
    }
}

@media screen and (max-width: 1440px) {
    .container_left {
        width: 1280px;
    }
}

@media screen and (max-width: 1280px) {
    .container_left {
        width: 1024px;
    }
}
