.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5% 0;
}

.top {
    text-align: center;
}

.top h1 {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: bolder;
    margin: 5px auto;
}
.top h3 {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: normal;
    margin: 5px auto;
}

.icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.icons img {
    width: 40px;
    height: 40px;
    margin: 10px;
    cursor: pointer;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.icons img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
