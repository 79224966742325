.container {
    flex-direction: column;
    justify-content: space-evenly;
    font-weight: lighter;
    width: 85%;
    margin: 0 auto;
    padding: 2% 0;
}

.top_box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}

.top_box h3 {
    font-weight: normal;
    font-size: 1.5rem;
}

.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    margin: 2% auto;
}

@media (max-width: 768px) {
    .container {
        width: 90%;
    }
}
