.input_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.description {
    font-size: 1rem;
    text-align: center;
}

.description a {
    font-size: 16px;
    font-weight: bold;
    color: #7F28C4;
    text-transform: uppercase;
}

.description a:hover {
    color: #6A17AD;
    /* text-decoration: underline; */
}

.name {
    font-weight: bold;
    margin-right: 2%;
}

.input {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input input {
    border: unset;
    border-bottom: 1px solid #707070;
    text-align: center;
    font-weight: bold;
    color: #6f6f6f;
    text-transform: uppercase;
    font-size: 1rem;
}

@media not all and (min-resolution: 0.001dpcm) {
    @media {
        .input input {
            -webkit-appearance: none;
            -webkit-border-radius: 0px;
        }
    }
}

.input input:focus,
.input input:active {
    outline: unset;
}

@media (max-width: 768px) {
    .input_box {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .input_container {
        margin-bottom: 20px;
    }
}
