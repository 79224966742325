:global #add {
    padding: 1% 5%;
}

/*top section*/
.top_section {
    width: 80%;
    margin: 0 auto 5%;
    text-align: center;
}

.top_section h3 {
    font-weight: normal;
    margin-bottom: 5%;
    font-size: 23px;
}

.top_section .text {
    font-size: 15px;
    font-weight: 300;
}

form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.form_section {
    width: 49%;
}

.pages {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.page_box {
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
    margin-bottom: 5%;
}

.top {
    border: 1px solid #e2e2e2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
}

.new {
    padding: 5% 0 15% 0;
    /* background-color: #eb7a76; */
    color: white;
    -moz-border-radius-topleft: inherit;
    -moz-border-radius-topright: inherit;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.logo {
    max-width: 75%;
    margin: 5% auto;
    display: block;
}

.text_bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    margin: auto;
}

.text_bottom p {
    font-size: 14px;
    font-weight: lighter;
    text-align: center;
}

@media (max-width: 768px) {
    form {
        flex-direction: column;
    }
}
