.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    padding: 0 2%;
    background-color: #5656A9;
}

@media (min-width: 768px) {
    .footer {
        padding: 20px 5%;
    }
}

.top_box,
.phone_box,
.email_box {
    display: flex;
    text-align: left;
    align-items: center;
}

.top_box h2 {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: normal;
    font-size: 1.3vw;
    font-weight: 700;
    margin: 0.3em 0;
}

.phone_box a,
.email_box a {
    font-family: 'Montserrat', Arial, sans-serif;
    font-size: 1.4vw;
    font-weight: 400;
    margin: 0.3em 0;
    display: table-cell;
    vertical-align: middle;
    width: 80%;
}

.phone_box img,
.email_box img {
    max-width: 2em;
    margin-right: 10px;
}

.top_box {
    padding-left: 5px;
}

@media screen and (max-width: 1680px) {
    .top_box h2 {
        font-size: 1em;
        margin: 0.2em 0 0.3em 0;
    }

    .phone_box a,
    .email_box a {
        font-size: 1.2vw;
        margin: 0.5em;
    }

    .phone_box img,
    .email_box img {
        max-width: 1.8em;
    }
}

@media (max-width: 900px) {
    .footer {
        padding: 5vw;
    }
}

@media screen and (max-width: 900px) {
    .opened.footer {
        display: none;
    }

    .phone_box a,
    .email_box a {
        font-size: 4vw;
    }

    .top_box h2 {
        font-size: 5vw;
        text-align: center;
    }
}
