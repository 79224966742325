.select_img {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    width: 50%;
    margin: 2% auto;
}

.top h2 {
    font-family: "Montserrat", Arial, sans-serif;
    font-weight: normal;
}

.content {
    font-family: "Montserrat", Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
}

.img_section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.img_box {
    width: 32%;
    cursor: pointer;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 0;
}

.img_box img {
    max-width: 100%;
    max-height: 100%;
    padding: 5px 0;
    align-self: center;
}

.img_box:hover,
.img_box.active {
    border-color: #cc2856;
}

@media (max-width: 768px) {
    .select_img {
        width: 90%;
    }
}
