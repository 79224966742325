.loader_component div {
    position: absolute;
    width: 100%;
    min-height: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;
    text-align: center;
}

.loader_component svg {
    margin-top: 20%;
    z-index: 2;
}
