.main {
    width: 70%;
    margin: 20px auto;
}

.main th {
    font-weight: normal;
    background-color: lightgrey;
    padding: 0.5em;
}
.tableflex {
    width: 70%;
    margin: 20px auto;
}

.tableflex > div:first-child {
    background: lightgray;
}

.tableflex > div:first-child > div {
    padding: 0.5em;
}

.tableflex > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tableflex > div > div {
    text-align: center;
}

.tableflex > div > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    flex-wrap: wrap;
}

.tableflex > div > div:nth-child(2) > div {
    width: 18%;
}

.tableflex > div > div:nth-child(2) > div:first-child {
    width: 35%;
}

.tableflex > div > div:first-child {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.tableflex button {
    padding: 6px 10px;
    font-size: 14px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

@media screen and (max-width: 1440px) {
    .main {
        width: 80%;
    }

    .tableflex {
        width: 95%;
    }

    .tableflex button {
        font-size: 12px;
    }

    @media (max-width: 992px) {
        .tableflex > div:first-child {
            display: none;
        }

        .tableflex > div {
            background: #fafafa;
            border: 1px solid #f0eded;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 20px 10px;
            margin-bottom: 20px;
        }

        .tableflex > div > div:first-child,
        .tableflex > div > div:nth-child(2) {
            width: 100%;
        }

        .tableflex > div > div:nth-child(2) {
            margin-bottom: 20px;
        }
        .tableflex > div {
            flex-direction: column-reverse;
        }

        .tableflex > div > div:nth-child(2) > div {
            width: 33%;
            font-size: 14px;
        }

        .tableflex > div > div:nth-child(2) > div:first-child {
            width: 100%;
            font-size: 20px;
            margin-bottom: 20px;
        }

        .tableflex button {
            width: 90px;
            padding: 0;
            font-size: 10px;
        }
    }
}
