@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

.top {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 auto;
    /* background-color: #eb7a76; */
    width: 100%;
    max-height: 1080px;
    position: relative;
}


.xmas [class*="message_box"] img,
.universal [class*="message_box"] img{
    filter: brightness(0);
  }

.liiv, .xmas, .universal{
    justify-content: flex-start;
    font-family: 'Montserrat', Arial, sans-serif;
    text-align: center;
}

.liiv [class*="editboxwrap"],
.liiv p,
.xmas [class*="editboxwrap"],
.xmas p
 {
    width: 50%;
    margin: 0 auto;
}

.top.universal h4,
.top.universal p,
.universal [class*="editboxwrap"] {
    width: 30%;
    margin-left: 60%;
}


.universal_home {
    display: flex;
    width: 30%;
    margin-left: 60%;
    margin-top: 200px;
}

.universal_home h2 {
    font-weight: 900;
    text-align: left;
    margin-left: 20px;
}

.liiv [class*="dark_dark"],
.liiv:after,
.xmas [class*="dark_dark"],
.xmas:after,
.universal [class*="dark_dark"],
.universal:after {
    background: none;
    background-color: transparent !important;
}

.top.liiv [class*="input_box"] input,
.top.liiv [class*="textarea"] textarea,
.top.liiv [class*="editbox"],
.top.liiv,
.top.liiv h1,
.top.liiv h4,
.top.liiv h5,
.top.liiv p {
    color: #7b6368 !important;
    font-family: 'Montserrat', Arial, sans-serif !important;
}

.top.xmas [class*="input_box"] input,
.top.xmas [class*="textarea"] textarea,
.top.xmas [class*="editbox"],
.top.xmas,
.top.xmas h1,
.top.xmas h4,
.top.xmas h5,
.top.xmas p,
.top.universal [class*="input_box"] input,
.top.universal [class*="textarea"] textarea,
.top.universal [class*="editbox"],
.top.universal,
.top.universal h1,
.top.universal h4,
.top.universal h5,
.top.universal p {
    color: #000 !important;
    font-family: 'Montserrat', Arial, sans-serif !important;
}

.top.xmas h1,
.top.xmas h4,
.top.xmas h5,
.top.universal h1,
.top.universal h4,
.top.universal h5 {
    font-weight: 900;
}


@media (max-width: 900px) {
    .top.xmas h4 {
        font-size: 23px;
    }
}

.top.xmas [class*="textarea"] textarea,
.top.universal [class*="textarea"] textarea {
    font-size: 2vw;
    height: auto;
    font-weight: 800;
}

@media (max-width:900px) {
    .top.xmas [class*="textarea"] textarea {
        font-size: 23px;
        height: auto !important;
    }
}


.xmas [class*="input_box"] input,
.universal [class*="input_box"] input {
    font-weight: 800;
    text-transform: uppercase;
}

.top.xmas [class*="textarea"]{
    margin-top:2vw;
}

.top.universal [class*="textarea"] {
    width: 30%;
}

.top.liiv [class*="input_box"] input,
.top.liiv [class*="textarea"] textarea,
.top.liiv h1,
.top.liiv h4,
.top.liiv h2 {
    font-family: "Abril Fatface" !important;
    font-weight: normal;
}

[class*="iconBox_img"] img {
    fill: #F27989 !important;

}


.textarea.hide {
    display: none;
}

.top.blue,
.top.green,
.top.purple {
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: start;
}

.top.blue {
    background-image: url(../../assets/img/background/temp5@2x.jpg);
}

.top.liiv {
    background-image: url(../../assets/img/background/liiv.jpg);
    background-position: center bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: auto !important;
    max-height: inherit !important;
    padding-bottom: 35vw;

    background-color: #f7f7f7;
}


.top.xmas {
    background-image: url(../../assets/img/background/xmas.jpg);
    background-position: center top;
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: auto !important;
    max-height: inherit !important;
    padding-bottom: 5vw;

}

.top.universal {
    background-image: url(../../assets/img/universal/bg.jpg);
    background-position: left 80px;
    background-size: 60% auto;
    background-repeat: no-repeat;
    min-height: auto !important;
    max-height: inherit !important;
    padding-bottom: 5vw;

}




.top.green {
    background-image: url(../../assets/img/background/temp6@2x.jpg);
}

.top.purple {
    background-image: url(../../assets/img/background/temp7@2x.jpg);
}

.section1,
.section2,
.section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.xmas .section3 {
    margin-top: 26vw;
}

.xmas .section1,
.universal .section1 {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 2vw;
}

.universal .section1 {
    margin-bottom: 50px;
}



@media (max-width: 900px) {

   
    .xmas .section1 {
        flex-direction: column;
    }

    .xmas .section3 {
        margin-top: 68vw;
        padding-bottom: 10vw;

    }

    .xmas .section3 p {
      font-weight: 400;
      width: 80%;

    }

}

.xmas .input_box,
.universal .input_box {
margin: 1% 0;
}

.section1 {
    padding-top: 1%;
}

.work_icon {
    position: absolute;
    text-align: center;
    font-weight: 700;
    line-height: 1.2;
}

.blue .work_icon {
    top: 0;
    left: -10%;
}

.purple .work_icon {
    top: 0;
    left: 10%;
}

.green .work_icon {
    top: 0;
    right: 5%;
}

.work_icon > div:first-child {
    margin-bottom: 20px;
}

.work_icon > div:first-child img {
    width: 3.8vw;
}

@media (max-width: 1680px) {
    .blue .work_icon {
        top: -40px;
        left: 10%;
    }

    .green .work_icon {
        top: -80px;
        right: 14%;
    }

    .purple .work_icon {
        top: -80px;
    }
}

@media (max-width: 1100px) {
    .purple .work_icon {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
    }
}

@media (max-width: 1024px) {
    section3 {
        flex-direction: column;
    }
    .green .work_icon,
    .blue .work_icon {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
    }
}

@media (max-width: 900px) {
    .work_icon {
        display: none;
    }
}

section3 {
    width: 100%;
}

.blue section3,
.purple section3 {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.green section3 {
    display: flex;
    position: relative;
}

.blue .section1,
.green .section1,
.purple .section1 {
    flex-direction: row;
}

.blue .section3,
.purple .section3 {
    width: 68.6%;
    margin: 0 auto;
    flex-direction: row;
    justify-content: flex-end;
}

.purple .section3 {
    width: 90%;
}

.blue .section3 .textarea,
.purple .section3 .textarea {
    width: 100%;
}

.section_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section_box.liiv {
    width: 95%;
    margin: auto;
}

.blue .section3 .section_box,
.purple .section3 .section_box {
    width: 41%;
}

.purple .section3 .section_box {
    padding: 0 2vw;
}

.green .section3 .section_box {
    width: 50%;
}

.green .section3 .textarea {
    width: 65%;
}

.blue .section3 h5,
.green .section3 h5,
.purple .section3 h5 {
    color: white;
    font-size: 1.2em;
    font-family: zona_pro_bold;
    font-weight: normal;
}

.blue .section3 h5 b,
.green .section3 h5 b,
.purple .section3 h5 b {
    font-family: zona_pro_bold;
    font-weight: normal;
}

.blue .section3 h4,
.green .section3 h4,
.purple .section3 h4 {
    font-size: 1.5em;
    font-family: zona_problack;
    font-weight: normal;
}

.blue .section3 h4 b,
.green .section3 h4 b,
.purple .section3 h4 b {
    font-family: zona_problack;
    font-weight: normal;
}

.blue .section3 p,
.green .section3 p,
.purple .section3 p {
    font-family: zona_pro;
}

.blue .section3 p b,
.green .section3 p b,
.purple .section3 p b {
    font-family: zona_pro_bold;
}

.input_box,
.textarea {
    border: 2px solid #dbdbdb;
    border-radius: 10px;
    padding: 1% 0;
    margin: 1% auto;
    width: 50%;
    position: relative;
}

.xmas .input_box,
.xmas .textarea,
.xmas [class*="editbox"],
.universal .input_box,
.universal .textarea,
.universal [class*="editbox"] {
    border-color: #000;
}

.universal .input_box,
.universal .textarea,
.universal [class*="editbox"] {
    margin-right: 10%;
}

.error {
    border-color: #cc2856;
}

.input_box_wide {
    padding: 3px 0;
    margin-bottom: 1px;
    width: 55%;
}

.input_box input,
.textarea.textarea_big textarea {
    width: 94%;
    height: 100%;
    font-size: 26px;
    padding: 0 3%;
    font-family: zona_problack;
    margin: auto;
    background-color: transparent;
    color: #ffffff;
    text-align: center;
}

.section1 .box {
    width: 60%;
    font-size: 26px;
    padding: 0 3%;
    font-family: zona_problack;
    margin: auto;
    background-color: transparent;
    color: #ffffff;
}

.xmas .section1 .box,
.universal .section1 .box  {
    margin: 1% 0;
    padding: 0;
    width: auto;
}

.section1 .box h1 {
    font-size: 2vw;
}

.xmas .section1 .box h1,
.universal .section1 .box h1 {
    text-transform: uppercase;
    width:80%;
    text-align: left;
}

@media (max-width:900px) {

    .xmas .section1 .box h1 {
        width:100%;
        text-align: center;
    }
    

}

.input_box input {
    border: unset;
}

.section1 div {
    margin-top: 2%;
    text-align: center;
}

.input_box input:focus {
    outline: unset;
}

.logo {
    display: block;
    margin: 2% auto;
    height: 40px;
}

@media (min-width: 1281px) {
    .logo {
        position: absolute;
        right: 50px;
        max-width: 10vw;
        height: auto;
    }
}

.blue .logo {
    margin-top: 2%;
}

.text {
    text-align: center;
    width: 40%;
    margin: 0 auto;
}

.section_box .text {
    width: 100%;
}

.universal .text {
    text-align: left;
}

.green .section_box .text {
    width: 80%;
}

.text h4 {
    font-family: zona_problack;
    font-size: 2vw;
    margin: 1vw 0;
    color: white;
}

.textarea textarea {
    background-color: transparent;
    width: 95%;
    border: unset;
    color: white;
    font-family: zona_pro;
    font-size: 17px;
    margin: auto;
    display: block;
    text-align: center;
    resize: none;
}

textarea:focus {
    outline: unset;
}

.test {
    background-color: red;
}

.product_img1,
.product_img2,
.product_img3 {
    position: absolute;
}

.product_img1 {
    width: 250px;
    bottom: 0;
}

.product_img2 {
    top: -50px;
    right: -180px;
    width: 470px;
}

.product_img3 {
    bottom: 0;
    right: 60px;
}

.product_img1 img,
.product_img2 img,
.product_img3 img {
    width: 100%;
    height: 100%;
}

.top {
    max-height: 1200px;
    min-height: 1000px;
}

@media screen and (max-width: 1680px) {
    .blue .section3,
    .purple .section3 {
        width: 90%;
    }

    .blue .section3 .section_box,
    .purple .section3 .section_box {
        width: 36%;
    }
}

@media (max-width: 1600px) {
    .green.top {
        min-height: 860px;
    }
}

@media screen and (max-width: 1100px) {
    .top {
        max-height: 1080px;
        min-height: 600px;
    }


    .top.liiv {
        background-size: contain;
        background-repeat: no-repeat;
        max-height: inherit;
        min-height: auto;
        padding-bottom: 40vw;
    }
}

@media screen and (max-width: 1620px) {
    .input_box_wide {
        width: 60%;
    }

    .section1 {
        padding-top: 0;
    }

    .blue .section3 .section_box {
        width: 30%;
    }
}

@media screen and (max-width: 1600px) {
    .input_box_wide {
        width: 65%;
    }

    .product_img1 {
        width: 200px;
    }

    .product_img2 {
        top: -20px;
        right: -150px;
        width: 420px;
    }

    .product_img3 {
        right: 40px;
    }

    .top.blue {
        background-position-x: 70%;
    }

    .blue .section3 {
        width: 90%;
    }

    .green .section3,
    .purple .section3 {
        width: 100%;
    }

    .blue .section3 .section_box {
        width: 63%;
    }
}

@media screen and (max-width: 1536px) {
    .top.purple {
        background-position-x: 50%;
    }

    .blue .section3 {
        width: 74%;
    }

    .purple .section3 {
        width: 95%;
    }

    .blue .section3 .section_box {
        width: 45%;
    }

    .purple .section3 .section_box {
        width: 40%;
    }
}

@media screen and (max-width: 1440px) {
    .blue .section3 {
        width: 80%;
    }

    .blue .section3 .section_box {
        width: 49%;
    }
}

@media screen and (max-width: 1280px) {
    .blue .section3 {
        width: 84%;
    }

    .blue .section3 .section_box {
        width: 51%;
    }

    .product_img1 {
        width: 170px;
    }

    .product_img2 {
        right: -140px;
        width: 360px;
    }

    .product_img3 {
        width: 90px;
    }

    .section1 div {
        margin: 10px auto;
    }

    .input_box,
    .textarea {
        width: 80%;
    }

    .liiv .input_box,
    .liiv .textarea,
   .liiv [class*="editboxwrap"],
   .liiv p {
        width:70%
    }

    .input_box_wide {
        width: 85%;
    }

    .input_box input {
        font-size: 4vw;
    }

    .blue .section1,
    .green .section1,
    .purple .section1 {
        flex-direction: column;
    }
}

@media (max-width: 1100px) {
    .top:after {
        content: "";
        flex-grow: 1;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .green .section3 .section_box,
    .purple .section3 .section_box {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .blue .section3,
    .purple .section3 {
        width: 90%;
        justify-content: center;
    }

    .blue .section3 .section_box,
    .purple .section3 .section_box {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {

    .top.xmas {
    background-image: url(../../assets/img/background/xmas_mobile.jpg);

    }
    .section1 {
        padding-top: 1em;
    }
    .blue .section1,
    .blue .section2,
    .blue .section3 {
        background: #6c9fd3;
        width: 100%;
    }

    .green .section1,
    .green .section2,
    .green .section3 {
        background: #46aba5;
        width: 100%;
    }

    .purple .section1,
    .purple .section2,
    .purple .section3 {
        background: #ad8cc0;
        width: 100%;
    }

    .top:after {
        background: none;
        height: 0;
    }

    .top.blue,
    .top.purple {
        background-size: 240% auto;
        background-position: 30% bottom;
        padding-bottom: 100vw;
        max-height: none;
        min-height: auto;
    }

    .top.green {
        background-size: 240% auto;
        background-position: 76% bottom;
        padding-bottom: 100vw;
        max-height: none;
        min-height: auto;
    }

    .section1 .logo {
        display: none;
    }

    .section3 {
        padding-bottom: 15vw;
    }

    .blue .section3 {
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5466561624649859) 0%,
            rgba(108, 159, 211, 1) 51%,
            rgba(108, 159, 211, 1) 100%
        );
        background: -webkit-linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5466561624649859) 0%,
            rgba(108, 159, 211, 1) 51%,
            rgba(108, 159, 211, 1) 100%
        );
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5466561624649859) 0%,
            rgba(108, 159, 211, 1) 51%,
            rgba(108, 159, 211, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#6c9fd3",GradientType=1);
    }

    .green .section3 {
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5466561624649859) 0%,
            rgba(70, 171, 165, 1) 51%,
            rgba(70, 171, 165, 1) 100%
        );
        background: -webkit-linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5466561624649859) 0%,
            rgba(70, 171, 165, 1) 51%,
            rgba(70, 171, 165, 1) 100%
        );
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5466561624649859) 0%,
            rgba(70, 171, 165, 1) 51%,
            rgba(70, 171, 165, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#46aba5",GradientType=1);
    }

    .purple .section3 {
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5466561624649859) 0%,
            rgba(173, 140, 192, 1) 51%,
            rgba(173, 140, 192, 1) 100%
        );
        background: -webkit-linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5466561624649859) 0%,
            rgba(173, 140, 192, 1) 51%,
            rgba(173, 140, 192, 1) 100%
        );
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5466561624649859) 0%,
            rgba(173, 140, 192, 1) 51%,
            rgba(173, 140, 192, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ad8cc0",GradientType=1);
    }

    .section3 p {
        font-weight: 700;
    }

   .liiv .section3 p {
        font-weight: 400;
    }

    
}

@media (max-width: 1200px) {
    .top.universal {
        background-image: url(../../assets/img/universal/bg-mobile.jpg);
        background-position: center -5vw;
        background-size: 100% auto;
        background-repeat: no-repeat;
        min-height: auto !important;
        max-height: inherit !important;
        padding-bottom: 5vw;
    
    }

    .top.universal [class*="textarea"] {
       margin-right: auto;
    }
    
    
    .top.universal h4,
    .top.universal p,
    .universal [class*="editboxwrap"] {
        width: 50%;
        margin: 0 auto;
        text-align: center;
    }
    
    .top.universal .section1 h1 {
        width: 100%;
        text-align: center;
        font-size: 1em;
    }
    
    .top.universal .section3 {
        margin-top: 50vw;
    }
    
    .universal .section1 {
        flex-direction: column;
    }

    .top.universal .universal_home {
        justify-content: center;
        align-items: center;
    margin:30px auto 0 auto;
    width: 70%;
    }
    .top.universal h4 {
        font-size: 23px;
        margin-bottom: 10px;
    }

    .top.universal p {
        font-weight: 400;
    width: 80%;
    }
    }

    @media (max-width: 900px) {
        .top.universal .section3 {
            margin-top: 80vw;
        }
    }

@media screen and (max-width: 768px) {
    .top.green {
        min-height: auto;
    }

    .top.blue {
        background-image: url(../../assets/img/background/mobile/temp5.jpg);
    }

    .top.green {
        background-image: url(../../assets/img/background/mobile/temp6.jpg);
    }

    .top.purple {
        background-image: url(../../assets/img/background/mobile/temp7.jpg);
    }

    .blue .section1 {
        flex-direction: column;
    }

    .section1 .box {
        text-align: center;
        width: 100%;
        padding: 0;
    }

    .section1 .box h1 {
        font-size: 1em;
    }

    .text {
        width: 100%;
    }

    .blue .section3 .section_box,
    .green .section3 .section_box,
    .purple .section3 .section_box {
        width: 65%;
        margin: 0 auto;
    }

    .green .section3 .section_box {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .section3 p {
        font-size: 4vw;
        line-height: 1.2;
    }
}

@media screen and (max-width: 414px) {
    .section1 .box h1 {
        margin: 0.2em;
    }

   .liiv .section1 .box h1 {
        margin: 1em;
    }
}

@media screen and (max-width: 375px) {
    .top.blue {
        min-height: calc(100vh - 18%);
    }

    .section1 .box h1 {
        font-size: 0.8em;
        margin: 0.3em;
    }

    .text h4 {
        font-size: 1em;
        margin: 1em;
    }

    .blue .section3 .section_box {
        width: 80%;
        margin: 0 auto;
    }

    .blue .section3 h5,
    .green .section3 h5,
    .purple .section3 h5 {
        font-size: 1em;
        margin: 1em;
    }
}
