.error_page {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-top: 5%;
}

.error_page > div {
    flex-direction: column;
    display: flex;
    align-items: center;
}

.error_page h1 {
    font-size: 40px;
    margin-bottom: 10px;
}

.error_page img {
    width: 90%;
    max-width: 300px;
    margin-bottom: 40px;
}
