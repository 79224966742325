.help {
    position: absolute;
    right: -40px;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.message_box {
    position: relative;
}

.message {
    font-size: 11px;
    position: absolute;
    background-color: white;
    padding: 10px;
    border-radius: 5px 5px  0 5px;
    bottom: 100%;
    right: 75%;
    display: none;
    width: 200px;
    color: black;
    z-index: 100;
}

.block {
    display: block;
}

.message_box:hover .message {
    display: block;
}

@media (max-width: 768px) {
    .help {
        right: -30px;
    }

    .help img {
        width: 20px;
    }
}
