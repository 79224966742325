.bottom {
    background-color: #dc4251;
    padding: 20% 0 5%;
}

.liiv {
    background: #fff;
}

.liiv div[class*="iconBox_text"] {
    color: #7b6368 !important;
    font-family: 'Montserrat', Arial, sans-serif !important;
    font-weight: 400;
}

.liiv h2 {
    font-family: "Abril Fatface" !important;
    color: #7b6368 !important;
    font-weight: normal;
}

.bottom_blue {
    background-color: #4679ad;
}

.bottom_green {
    background-color: #389a95;
}

.bottom_purple {
    background-color: #4d266b;
}

.input_box input {
    width: 94%;
    border: unset;
    height: 100%;
    font-size: 26px;
    padding: 0 3%;
    font-family: zona_pro;
    margin: auto;
    background-color: transparent;
    color: #ffffff;
    text-align: center;
}

.input_box input:focus {
    outline: unset;
}

.text {
    text-align: center;
    padding-bottom: 100px;
    width: 90%;
    margin: auto;
}

.text h2 {
    font-family: zona_pro;
    font-weight: 200;
}

.xmasfooter, .u_footer {
    position: relative;
}

.xmasfooter img, .u_footer img {
    width: 100%;
}

.xmasfooter a,
.u_footer a {
    position: absolute;
    display: block;
    width: 5%;
    height: 8%;
}

.xmasfooter :global .play_link {
    left:6%;
    top:76%;
}

.xmasfooter :global .store_link {
    left:17.5%;
    top:76%;
}


.u_footer :global .play_link {
    left:12%;
    top:79%;
}

.u_footer :global .store_link {
    left:22.5%;
    top:79%;
}


@media screen and (max-width: 1536px) {
    .bottom {
        padding: 25% 0 5%;
    }
}

@media screen and (max-width: 1440px) {
    .bottom_blue {
        padding-top: 30%;
    }

    .text {
        padding-bottom: 50px;
    }
}

@media screen and (max-width: 1366px) {
    .bottom_blue {
        padding-top: 35%;
    }
}

@media screen and (max-width: 1366px) {
    .bottom_blue {
        padding-top: 135px;
        padding-bottom: 150px;
    }
}

@media screen and (max-width: 900px) {
    .bottom,
    .bottom_blue {
        padding-top: 10vw;
        padding-bottom: 10vw;
    }

    .bottom_blue {
        background: #315579;
    }

    .text h2 {
        font-size: 1.5em;
        font-weight: 900;
    }


    .xmasfooter a,
    .u_footer a {
        position: absolute;
        display: block;
        width: 20%;
        height: 8%;
    }
    
    .xmasfooter :global .play_link,
    .u_footer :global .play_link {
        left:25%;
        top:50%;
    }
    
    .xmasfooter :global .store_link,
    .u_footer :global .store_link {
        left:53%;
        top:50%;
    }
}
