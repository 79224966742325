.page_box {
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
    margin-bottom: 5%;
}

.top {
    border: 1px solid #e2e2e2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
}

.new {
    max-height: 65%;
    padding: 5% 0 15% 0;
    /* background-color: #eb7a76; */
    color: white;
    -moz-border-radius-topleft: inherit;
    -moz-border-radius-topright: inherit;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.temp_5 {
    background-color: #7ba3cb;
}

.temp_6 {
    background-color: #159d98;
}

.temp_7 {
    background-color: #b99cca;
}

.liiv {
    padding: 15% 0;
    background: #EEEEF0;
}

.xmas {
    background-image: url(../../assets/img/tiles_xmas.png);
    background-size: cover;
}

.cleo {
    background-image: url(../../assets/img/new_template/baner.jpg);
    background-size: cover;
}

.cleo h6, .cleo .logo{
    opacity: 0;
}

.universal {
    background-image: url(../../assets/img/tiles_u.jpg);
    background-size: cover;
}

.new.xmas h6,
.new.universal h6 {
    font-weight: 700;
}

.new h6 {
    font-weight: normal;
    font-family: 'Montserrat', Arial, sans-serif;
    font-size: 18px;
    text-align: center;
}

.logo {
    max-width: 75%;
    margin: 5% auto;
    display: block;
}

.text_bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65%;
    margin: auto;
}

.text_bottom p {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
}

@media (max-width: 768px) {
    .page_box {
        width: 100%;
    }

    .new {
        padding: 10px;
    }

    .new h6 {
        margin: 10px;
    }

    .text_bottom {
        text-align: center;
    }

    .page_box {
        min-width: 300px;
    }
}
