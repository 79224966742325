.box {
    text-align: center;
    padding: 5%;
}

.content {
    margin: 5% auto;
}

.content h2 {
    font-weight: 200;
}
