.toolbar {
    display: none;
    position: absolute;
    bottom: -100px;
    right: 0px;
    width: 250px;
    z-index: 1;
}

.message {
    background: #fff;
    color: #171717;
    padding: 10px 5px;
    border-radius: 5px;
    overflow: hidden;
    border-left: 6px solid #ffba00;
    font-size: 12px;
    margin-top: 3px;
    text-align: left;
    font-family: 'Montserrat', Arial, sans-serif;
}

.buttons {
    background: #fff;
    font-size: 20px;
    display: flex;
    pointer-events: all;
    -webkit-box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.29);
    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.buttons button {
    background: #fff;
    border: none !important;
    border-radius: 0;
    font-weight: 700;
    outline: none;
    cursor: pointer;
    padding: 5px;
    display: block;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
}

.buttons button:hover {
    background: #cccccc;
}

.toolbar.active {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;
}

.switcher {
    cursor: pointer;
}

.editboxwrap {
    position: relative;
    border: 2px solid #dbdbdb;
    border-radius: 10px;
    padding: 1% 0;
    margin: 1% auto;
    width: 100%;
    text-align: center;
    font-family: zona_pro;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px;
}

.editboxwrap.active {
    border-color: #fff;
    margin-bottom: 105px;
}

.editboxwrap.error {
    border-color: #cc2856;
}

.editbox:focus {
    outline: 0px solid transparent !important;
}

.switcher {
    position: absolute;
    right: -65px;
    display: flex;
    flex-direction: column;
    top: -5px;
    bottom: 0;
    justify-content: center;
}

@media (max-width: 768px) {
    .switcher {
        right: -60px;
    }
}
