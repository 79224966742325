.modal {
    display: block; /* Hidden by default */
    position: absolute; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    min-height: 100%; /* Full height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.35); /* Black w/ opacity */
    padding: 60px 0;
    height: 100vh;
}

.modal_content {
    position: fixed;
    background-color: #fefefe;
    margin: 0 auto; /* 5% from the top, 15% from the bottom and centered */
    width: 70%; /* Could be more or less, depending on screen size */
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s;
    border-radius: 20px;
    left: 15%;
    top: 10vh;
    margin-top: 0 !important;
    z-index: 9999;
    max-height: 90vh;
    overflow-y: auto;
}

/* The Close Button (x) */
.close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: black;
    width: 20px;
    height: 20px;
    display: table-cell;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: transform;
    padding-bottom: 2px;
}

.close:hover,
.close:focus {
    cursor: pointer;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    .modal_content {
        width: 90%;
        left:5%;
        top: 10vh;
        margin-top: 0 !important;
        max-height: 90vh;
        overflow-y: scroll;
    }
}
