.video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    font-family: 'Montserrat', Arial, sans-serif;
    padding: 2% 0;
}

.top {
    text-align: center;
    font-weight: 200;
}

.top h2 {
    font-weight: 200;
}

.video_box {
    width: 60%;
    text-align: center;
    margin: 1% auto;
}

.video_box div {
    padding: 10px;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
}

.video_box iframe {
    max-width: 100%;
}

.input_box {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input_box input {
    width: 75%;
    border-radius: 5px;
    font-size: 23px;
    height: 32px;
    border: #d9d9d9 1px solid;
    font-weight: lighter;
    margin: 8px;
    padding-left: 10px;
}

.input_box button {
    width: 15%;
    /* padding: 0px 0px; */
    /* margin: 0px 0px; */
}

.input_box input:focus {
    outline: unset;
}

.error {
    position: relative;
}

.error input {
    border-color: red;
}

.error_msg {
    display: none;
}

.error .error_msg {
    display: block;
    width: 200px;
    background-color: red;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 20%;
}

@media (max-width: 768px) {
    .video_box,
    .input_box {
        width: 90%;
    }

    .input_box button {
        padding: 0;
    }
}
