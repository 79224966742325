.section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    width: 75%;
    margin: 0 auto;
    font-weight: 300;
}

.top {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto 2%;
}

.top p {
    font-size: 16px;
}

.top h3,
.top h5,
.bottom h5 {
    font-weight: 300;
    margin: 0;
    font-size: 16px;
}

.top h3 {
    font-size: 23px;
}

.top h5 {
    text-align: left;
}

.top a {
    color: #0359e2;
    text-decoration: underline;
}

.chart {
    overflow-x: auto;
    margin: 20px 0;
    scrollbar-width: none;
}

/* width */
.chart::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.chart::-webkit-scrollbar-track {
    background: #ebebeb;
    border-radius: 10px;
}

/* Handle */
.chart::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
}

/*section.section div.chart :first-child {*/
/*    width: 100%;*/
/*}*/

.bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
}

.bottom p {
    font-size: 14px;
}

.text_box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.data_section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
