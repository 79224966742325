.main h1 {
    font-size: 3em;
}

.main h2 {
    font-size: 2em;
}

.main h3 {
    font-size: 1.25em;
    font-weight: normal;
}

p {
    font-size: 1em;
}

.main .typHeader {
 font-weight: 900;
 font-size: 35px;
 color: #E5004B;
}


.main .h3-m-b {
    margin-bottom: 5%;
}

.main {

    color: #212121;
    text-align: center;
    font-size: 20px;
    width: 100%;
    padding: 100px 0;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}

.main .container-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.main .bottomTextBox {
    text-align: center;
}

.main .button {
    margin:auto !important;
    background-color: #212121 !important;
    transition: all 0.3s;
    text-transform: uppercase;
    font-size: 14px !important;
}

.main .button::before {
    content: "Przejdź do strony logowania";
}

.main .button:hover {
    background-color: #E5004B !important;
}

@media (max-width: 758px) {
    .main .typHeader {
        font-size: 32px;
    }
    .main .button {
        font-size: 12px !important;
    }
}


.main .bottom-text-box h3 {
    font-family: zona_pro;
    margin-bottom: 2%;
}

.main .img-box {
    width: 100%;
}

.main .img-box img {
    max-width: 60%;
    margin: 3em;
}

.main .btn-cstm {
    font-family: zona_pro;
    letter-spacing: 3px;
    margin: 20px 0;
    padding: 15px 40px;
    font-size: 1.2em;
    text-align: center;
    color: white;
    background-color: black;
    border: none;
    cursor: pointer;
}
