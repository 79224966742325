
.btn {
    width: 168px;
    right: 5%;
    top: 50%;

    font-family: 'Montserrat', Arial, sans-serif;
    display: inline-block;
    line-height: 24px;
    /* margin: 0px 10px; */
    padding: 6px 10px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    position: relative;
    border: 2px solid #7F28C4;
    border-radius: 4px;
    background-color: #ffffff;
    color: #7F28C4;
    cursor: pointer;

    position: absolute;
    margin: 8px;
}

@media (min-width: 768px) {
    .btn.select_picture {
        top: 50%;
        right: -39%;
    }
}

@media (min-width: 1440px) and (max-width: 1619px) {
    .btn.select_picture {
        right: -28%;
    }
}


.btn:hover {
    border: 2px solid transparent;
    background-color: rgba(106, 23, 173, 0.05);
    color: #6A17AD;
}

.btn:focus,
.btn:active {
    outline: unset;
}


@media screen and (max-width: 1440px) {
    .btn {
        right: 0;
    }

    .btn.select_picture {
        right: -28%;
    }
}

@media screen and (max-width: 767px) {
    .btn.select_picture {
        right: -15%;
    }
}
