
.btn {
    font-family: 'Montserrat', Arial, sans-serif;
    display: inline-block;
    line-height: 24px;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    position: relative;
    border: 2px solid transparent;
    border-radius: 4px;
    background-color: #7F28C4;
    color: #ffffff;
    cursor: pointer;
}

.external {
    background-color: #7F28C4;
    color: #ffffff;
}

.btn_inactive {
    border: 2px solid #7F28C4;
    background-color: rgba(162, 172, 174, 0.3);
    color: #7F28C4;
    letter-spacing: 1.5px;
}


.btn:hover {
    background-color: #6A17AD;
    color: #ffffff;
}

.external:hover {
    background-color: #6A17AD;
    color: #ffffff;
}

.btn_inactive:hover {
    border: 2px solid #6A17AD;
    background-color: rgba(162, 172, 174, 0.3);
    color: #6A17AD;
}


.narrow {
    width: 50%;
    margin: 5% auto 0;
}

.btn:active,
.btn:focus {
    outline: unset;
}
