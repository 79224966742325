/* Form styles */
.form_container {
    width: 45%;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: hsla(0, 0%, 100%, .72);
    animation: AprilForm_fadeIn__1N_Pi 1s linear;
    display: flex;
    flex-direction: column;
    color: #181818;
    text-align: left;
    font-size: 14px;
    justify-content: space-between;
    padding-top: 2vh;
    width: 100%;
}

.secondary {
    color: #E5004B;
}   

.pointer {
    cursor: pointer;
}   

.customPlaceholder {
    position: absolute;
    top: 8px;
    left: 10px;
    color: #212121;
    transition: all 0.3s;
    pointer-events: none;
  }

  .focusedPlaceholder {
    position: absolute;
    top: -18px;
    left: 0;
    color: #828282;
    font-size: 12px;
    transition: all 0.3s;
    pointer-events: none;
  }
  
  .date_picker_wrapper {
    position: relative;
  }






.errorBox {
    position: absolute;
    right: 30px;
    top: 3px;
    margin: 0 !important;
    width: auto;
    padding: 8px;
    border-radius: 25px;
}

.errorBox .errorBoxContent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
}

.errorBox img {
    width: 15px;
    height: 15px;
}

.errorBox:hover .error {
    display: block;
    min-width: 150px;
}

.error {
    font-family: zona_prosemibold;
    font-size: 11px;
    color: #af0c0c;
    background-color: #FFEFF4;
    padding: 5px;
    margin: 0 !important;
    display: none;
    position: absolute;
    bottom: 110%;
    right: 50%;
    width: auto;
}

.error.left {
    right: unset;
    left: 25%;
}

.input_grid {
    display: grid;
    gap: 23px;
    row-gap: 23px;
  }

  .full_width {
    grid-column: span 2;
  }
  
  .half_width {
    grid-column: span 1;
  }

  @media (max-width: 574px) {
    .half_width  {
        grid-column: span 2;
    }
  }
  
  .date_picker_wrapper {
    position: relative;
  }
  
  .date_picker_input {
    width: 100%;
    display: block !important;
  }

  
  .calendar_icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

.universal.form_container {
    background-color: #6E2381;
}

.universal [class*="formfooter_footer"] {
    background-color: #293132 !important;
}

.universal [class*="form_top"] img {
    display: none;
}

.universal [class*="form_top"] h3 {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1em;
}

@media (min-width: 901px) {
    .form_container {

        z-index: 100;
    }
}

.form_container form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.formscroll {
    min-height: 100%;
}

.formscrollContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}



.top {
    display: flex;
    flex-direction: row;
    justify-content: left;
    position: relative;
    align-items: center;
}

.top img {
    max-height: 4em;
}

.top .navigate {
    display: none;
}

.form_box {
    padding-left: 10px;
    padding-right: 10px;
}


.agreement_checkmark {
    border: 1px solid #212121;
    top:-2px;
    width: 16px;
    height: 16px;
}

.agreement_checkmark::after {
    /* scale 0.5 and rotate 40   */
    transform: scale(0.8) rotate(40deg) !important;
    top: -2px !important;

}

.agreementClass {
    margin-top:20px;
}

.agreementClass a {
    color: #E5004B !important;
    text-decoration: underline;
    font-weight: 700;
}

.agreementClass label {
    padding-left: 35px !important;
}

.form_container input {
    padding-left: 0;
    border: 1px solid #C7C7C7;
    font-family: 'Montserrat', Arial, sans-serif;
    background-color: transparent;
    color: #181818;
    font-size: 14px;
    padding: 0.6em;
    border-radius: 0;
    height: auto;
    line-height: normal;
}
.form_container input:active, .form_container input:focus {
    padding: 0.6em;
    box-shadow: none;
    border: 1px solid #616161;
    background: transparent;
}

input.focused {
    border: 1px solid #616161;
  }

@media not all and (min-resolution: 0.001dpcm) {
    @media {
        .form_container input {
            -webkit-appearance: none;
            -webkit-border-radius: 0px;
        }
    }
}

.form_container :focus {
    outline: none;
}

form div,
form div label,
form div input {
    width: 100%;
}


::placeholder {
    opacity: 1;
    font-size: 14px !important;
}

:global form .street {
    width: 45%;
}

:global form .phone {
    width: 44%;
}


:global form > div.birth-date {
    width: 40%;
    margin-top: 3%;
}

:global form .street-no,
:global form .apartment-no {
    width: 24%;
}

:global #BirthDate {
    display: none;
}

:global form .post-code {
    width: 36%;
}

:global form .city {
    width: 60%;
}

:global form .upl_email {
    /*width: 45%;*/
}

:global form .birth-date .react-datepicker-wrapper div,
:global form .birth-date .react-datepicker__tab-loop div,
:global form .birth-date .react-datepicker-wrapper {
    margin: 0;
}

.errorBg {
    background-color: #ffd0d6;
    border: 2px solid #000000 !important;
}

a {
    text-decoration: none;
}

:global .text-input p {
    text-align: left;
    font-size: 0.7em;
    margin: 5px 0 3px 0;
}

:global label.container {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: left;
}

.form_container button[type="submit"] {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: 600;
    margin: 5px 0;
    padding: 16px 30px;
    font-size: 1em;
    text-align: center;
    color: white;
    background-color: #212121;
    border: none;
    cursor: pointer;
    display: block;
    text-wrap: nowrap;
    transition: all 0.3s ease-in-out;
}

.form_container button[type="submit"]:hover {
    background-color: #E5004B;
}




.form_container .agreement {
    margin-bottom: 15px;
}

:global .flex {
    display: flex;
    margin-bottom: 10px;
}

.mobile {
    display: none;
}









