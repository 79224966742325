.correct {
    position: absolute;
    top: 10px;
    right: 15px;
}

.full_width {
    grid-column: span 2;
  }
  
  .half_width {
    grid-column: span 1;
  }


  /* @media (max-width: 574px) {
    .half_width  {
        grid-column: span 2;
    }
  } */
  

.pickerlabel {
    font-size: 0.4em;
    margin-bottom: 5px;
}

.container {
    position: relative;
  }
  
  .inputField {
    width: 100%;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .inputField:focus {
    border-color: #616161;
  }
  
  .inputField::placeholder {
    color: transparent;
  }
  
  .label {
    position: absolute;
    top: 8px;
    left: 10px;
    color: #212121;
    transition: all 0.3s;
    pointer-events: none;
  }
  
  .inputField:focus + .label,
  .inputField:not(:placeholder-shown) + .label {
    top: -18px;
    left: 0;
    color: #828282;
    font-size: 12px;
  }

@media not all and (min-resolution: 0.001dpcm) {
    @media {
        .correct {
            top: 13px;
        }
    }
}

.correct:after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 6px;
    height: 13px;
    border: solid #2fa85a;
    border-width: 0px 2px 2px 0;
    transform: rotate(40deg);
}

.textInput {
    position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::placeholder {
    font-size: 14px;
}

@media (min-width: 1621px) {
    input::placeholder {
        font-size: 14px;
    }

    .pickerlabel {
        font-size: 14px;
        margin-bottom: 5px;
    }
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.errorBox {
    position: absolute;
    right: 0;
    top: 3px;
    margin: 0 !important;
    width: auto;
    padding: 8px;
    border-radius: 25px;
}

.errorBox .errorBoxContent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
}

.errorBox img {
    width: 15px;
    height: 15px;
}

.errorBox:hover .error {
    display: block;
    min-width: 150px;
}

.error {
    font-family: zona_prosemibold;
    font-size: 11px;
    color: #af0c0c;
    background-color: #FFEFF4;
    padding: 5px;
    margin: 0 !important;
    display: none;
    position: absolute;
    bottom: 110%;
    right: 50%;
    width: auto;
}

.error.left {
    right: unset;
    left: 25%;
}



