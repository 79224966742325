.input {
    padding: 1%;
    width: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    position: relative;
}

.input label {
    width: 25%;
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    margin-right: 10px;
}

.input_box {
    width: 75%;
}

.input_box p {
    font-size: 10px;
    font-weight: normal;
}

.input input {
    width: 100%;
    margin: 1px;
    border: 1px #d9d9d9 solid;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
}

.input input:focus {
    outline: unset;
}

.error input {
    border: 1px solid red;
}

.err_msg {
    width: 170px;
    background-color: red;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
}

input:-internal-autofill-selected {
-webkit-text-fill-color: #fff;
-webkit-box-shadow: 0 0 0px 1000px #D991B7 inset;
font-size: 13px;
}

@media (max-width: 768px) {
    .input {
        flex-direction: column;
        width: 100%;
    }
    .input label {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
    }

    .input_box {
        width: 100%;
    }
}

@media (max-width: 440px) {
  .err_msg {
      bottom: 75%;
  }
}
