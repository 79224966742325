.main {
    width: 100%;
    height: 100vh;
    background-color: #4679ad;
    position: relative;
}

.bg {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/img/background/temp5@2x.jpg);
    filter: blur(5px);
    -webkit-filter: blur(5px);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.text_box {
    color: white;
    font-weight: bold;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
}

.text_box img {
    margin-bottom: 5%;
}

.main h1 {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: bolder;
    font-size: 2rem;
    margin-bottom: 10%;
}

.main h4 {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 5%;
}

@media screen and (max-width: 414px) {
    .text_box {
        width: 100%;
        top: 35%;
    }
    .main h1 {
        font-size: 1rem;
    }

    .main h4 {
        font-size: 0.7rem;
    }
}
