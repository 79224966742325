.box {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 26%;
    color: white;
}

.text {
    font-family: zona_pro;
    font-size: 14px;
    text-align: center;
    width: 95%;
    margin: 0 auto;
}

.big .text {
    font-size: 17px;
    font-weight: bold;
}

.img {
    text-align: center;
    height: 30%;
    margin-bottom: 5%;
}

.img img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    height: 100%;
}

.text p {
    margin: 0;
}

@media screen and (max-width: 1620px) {
    .img {
        height: 25%;
        margin-bottom: 3%;
    }
}

@media screen and (max-width: 768px) {
    .box {
        height: 100%;
    }
}

@media (min-width: 901px) {
    .iconsbottom:last-child {
        display: none;
    }
}

@media (max-width: 900px) {
    .iconsmiddle .text {
        font-weight: 700;
    }

    .iconsmiddle img {
        width: 15vw;
    }

    .iconsbottom {
        margin-bottom: 5vw;
    }

    .iconsbottom .img {
        margin-bottom: 0;
        width: 25vw;
    }
    .iconsbottom img {
        margin-bottom: 0;
        width: 13vw;
        max-width: 80px;
    }

    .iconsbottom {
        flex-direction: row;
        align-items: center;
        width: 80%;
    }

    .iconsbottom .text {
        text-align: left;
        font-size: 3vw;
    }
}

@media screen and (max-width: 480px) {
    .iconsmiddle {
        flex-direction: row;
        align-items: center;
    }

    .iconsmiddle .text {
        text-align: left;
        font-size: 4vw;
        padding-right: 10vw;
    }

    .box {
        width: 100%;
    }

    .iconsbottom {
        width: 90%;
    }

    .img {
        width: 25%;
        margin: 0 auto 10px;
    }

    .iconsmiddle img {
        width: 15vw;
    }

    .text,
    .big .text {
        font-size: 1.1em;
        width: 70%;
    }
}
