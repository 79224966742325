/* Form styles */
.form_container {
    width: 45%;
    background-color: #DA85B2;
    font-family: zona_pro;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    font-size: 20px;
    justify-content: space-between;
    padding-top: 2vh;
}

.liiv.form_container {
    background-color: #f17086;
}

.xmas.form_container {
    background-color: #821338;
}

.liiv [class*="formfooter_footer"] {
    background-color: #fa8c9f !important;
}

.xmas [class*="formfooter_footer"] {
    background-color: #293132 !important;
}

.xmas [class*="form_top"] img {
    display: none;
}

.xmas [class*="form_top"] h3 {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1em;
}

.universal.form_container {
    background-color: #6E2381;
}

.universal [class*="formfooter_footer"] {
    background-color: #293132 !important;
}

.universal [class*="form_top"] img {
    display: none;
}

.universal [class*="form_top"] h3 {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1em;
}

@media (min-width: 901px) {
    .form_container {
        -webkit-box-shadow: -10px 0px 19px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: -10px 0px 19px 0px rgba(0, 0, 0, 0.15);
        box-shadow: -10px 0px 19px 0px rgba(0, 0, 0, 0.15);
        z-index: 100;
    }
}

.form_container form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.formscroll {
    min-height: 100%;
}

.formscrollContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}

@media (min-width: 1621px) {
    .formscrollContent {
        min-height: 98vh;
    }
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: left;
    position: relative;
    align-items: center;
}

.top img {
    max-height: 4em;
}

.top .navigate {
    display: none;
}

.form_box {
    padding-left: 10px;
    padding-right: 10px;
}

.form_box form > div {
    margin: 5% 0 0;
}

.form_container input {
    padding-left: 0;
    border: none;
    font-family: 'Montserrat', Arial, sans-serif;
    background-color: transparent;
    border-bottom: 1px solid white;
    color: white;
    font-size: 19px;
}

@media not all and (min-resolution: 0.001dpcm) {
    @media {
        .form_container input {
            -webkit-appearance: none;
            -webkit-border-radius: 0px;
        }
    }
}

.form_container :focus {
    outline: none;
}

form div,
form div label,
form div input {
    width: 100%;
}

.form_container h3 {
    font-weight: bold;
    margin: 0.4em 0;
    font-size: 0.8em;
}

::placeholder {
    color: white;
    opacity: 1;
}

:global form .street {
    width: 45%;
}

:global form .phone {
    width: 44%;
}

@media (min-width: 1621px) {
    :global form .phone {
        width: 52%;
    }
}

:global form > div.birth-date {
    width: 40%;
    margin-top: 3%;
}

:global form .street-no,
:global form .apartment-no {
    width: 24%;
}

:global #BirthDate {
    display: none;
}

:global form .post-code {
    width: 36%;
}

:global form .city {
    width: 60%;
}

:global form .upl_email {
    /*width: 45%;*/
}

:global form .birth-date .react-datepicker-wrapper div,
:global form .birth-date .react-datepicker__tab-loop div,
:global form .birth-date .react-datepicker-wrapper {
    margin: 0;
}

.errorBg {
    background-color: #ffd0d6;
    border: 2px solid #000000 !important;
}

a {
    text-decoration: none;
    color: white;
}

:global .text-input p {
    text-align: left;
    font-size: 0.7em;
    margin: 5px 0 3px 0;
}

:global label.container {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: left;
}

.form_container button[type="submit"] {
    font-family: 'Montserrat', Arial, sans-serif;
    letter-spacing: 3px;
    margin: 5px 0;
    padding: 10px 30px;
    font-size: 1.2em;
    text-align: center;
    color: #d991b7;
    background-color: white;
    border: none;
    border-radius: 1px;
    cursor: pointer;
}

.xmas button[type="submit"],
.universal button[type="submit"]
 {
    color: #fff;
    text-transform: uppercase;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 1px;
    cursor: pointer;
}


.form_container .agreement {
    margin-bottom: 15px;
}

:global .flex {
    display: flex;
    margin-bottom: 10px;
}

.mobile {
    display: none;
}

@media screen and (max-width: 1620px) {
    :global form > div.birth-date {
        width: 50%;
        margin-top: 3%;
    }

    .form_container {
        padding-top: 0;
    }

    .form_box form > div {
        margin: 3% 0 0;
    }

    .city {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1536px) {
}

@media screen and (max-width: 1440px) {
    .form_container button[type="submit"] {
        font-size: 0.7em;
    }

    .form_box form > div {
        margin-top: 2.5%;
    }
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
    .form_container {
        width: 100%;
    }

    .form_box form > div {
        margin: 5vw 0 0;
    }

    :global form .phone {
        width: 45%;
    }

    :global form .birth-date {
        width: 100%;
    }

    :global form .birth-date .react-datepicker-wrapper div,
    :global form .birth-date .react-datepicker__tab-loop div,
    :global form .birth-date .react-datepicker-wrapper {
        margin: 0;
    }
}

@media screen and (max-width: 834px) {
}

@media screen and (max-width: 680px) {
    .top h3 {
        font-size: 2.7vw;
    }

    .top {
        margin-bottom: 0;
        padding: 6px 0;
    }
}

@media screen and (max-width: 900px) {
    :global form div.city {
        margin-bottom: 7vw;
        width: 100%;
    }

    :global form .post-code {
        width: 46%;
    }

    :global form .street-no {
        width: 30%;
    }

    .form_container {
        width: 100%;
        bottom: 0;
        overflow: hidden;
        justify-content: flex-end;
        -webkit-transition-property: width, height, left, top, bottom;
        -webkit-transition-duration: 0.5s;
        -webkit-transition-timing-function: ease-in-out;
        transition-property: width, height, left, top, bottom;
        transition-duration: 0.5s;
        transition-timing-function: ease-in-out;
    }

    .form_box {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .logo {
        margin: 0 auto;
        height: auto;
        width: 30vw;
        margin-bottom: 3vw;
    }

    .top img {
        width: 13vw;
    }

    .open .top img {
        max-height: 2em;
    }

    .top .navigate {
        margin: 0 1em;
        display: flex;
    }

    .top .navigate img {
        transform: rotate(0deg);
        transition: 0.5s linear all;
    }

    .open .top .navigate img {
        transform: rotate(-180deg);
        transition: 0.5s linear all;
    }

    .form_container.open form {
        overflow: visible;
    }

    .form_container.open form {
        height: 100%;
    }

    .top h3 {
        font-size: 5vw;
    }

    .open .form_box {
        padding: 4%;
    }

    .open .form_box form div {
        margin-top: 4%;
    }

    .form_container button[type="submit"] {
        font-size: 0.8em;
        width: 50%;
        padding: 15px 0;
        margin: 0 auto;
        border-radius: 1;
        display: block;
        font-weight: 700;
    }

    :global form .apartment-no {
        width: 48%;
    }

    .open {
        height: 98%;
        justify-content: space-between;
    }

    .mobile {
        display: block;
    }

    .desktop {
        display: none;
    }

    :global form .birth-date .react-datepicker-wrapper div,
    :global form .birth-date .react-datepicker__tab-loop div,
    :global form .birth-date .react-datepicker-wrapper {
        margin: 0 !important;
    }

    :global form .street {
        width: 61%;
    }
}

@media screen and (max-width: 600px) {
    .form_container,
    .form_box {
        width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .open .form_box form div {
        margin-top: 2%;
    }

    .open {
        height: 98%;
    }
}

@media screen and (max-width: 375px) {
    .form_container {
        font-size: 18px;
    }

    .form_container input {
        font-size: 1.1em;
        height: 30px;
    }

    .open {
        height: 95%;
    }

    .open .form_box {
        padding-bottom: 9%;
    }

    .form_container input {
        font-size: 0.7em;
        height: unset;
    }
}

@media screen and (max-width: 320px) {
    .open {
        height: 99%;
    }
}

@media (min-width: 901px) {
    .form_container,
    .form_box {
        width: 350px;
    }
}

@media (min-width: 1100px) {
    .form_container,
    .form_box {
        width: 380px;
    }
}

@media (min-width: 1620px) {
    .form_container,
    .form_box {
        width: 500px;
    }
}

@media (min-width: 1800px) {
    .form_container,
    .form_box {
        width: 600px;
    }
}
