.preview {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    height: 100vh;
    overflow: hidden;
}

@media screen and (max-height: 1200px) {
    .top {
        max-height: 1200px;
    }
}

@media screen and (max-height: 1080px) {
    .top {
        max-height: 1080px;
    }
}

@media screen and (max-height: 900px) {
    .top {
        max-height: 900px;
    }
}

@media screen and (max-height: 768px) {
    .top {
        max-height: 768px;
    }
}

@media (max-width: 900px) {
    .preview {
        flex-direction: column-reverse;
        height: auto;
        max-height: none;
    }
}
